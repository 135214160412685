import type { PropsWithChildren } from 'react';
import * as React from 'react';

import { ApolloProvider } from '@apollo/client';
import type ApolloClient from 'apollo-client';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import provideContext from 'vendor/cnpm/fluxible.v0-4/addons/provideContext';

import { Provider as CdsProvider } from '@coursera/cds-core';

import NaptimeContextAdapter from 'bundles/naptimejs/util/NaptimeContextAdapter';
import ErrorBoundaryWithLogging from 'bundles/page/components/ErrorBoundaryWithLogging';
import InternetExplorerBanner, {
  InternetExplorerBannerWithErrorBoundary,
} from 'bundles/page/components/InternetExplorerBanner';
import { AppInfoProvider } from 'bundles/page/contexts/AppInfoContext';
import { IntlShimProvider } from 'bundles/page/lib/useIntl';

// TODO replace with IntlProvider from react-intl v2
// note: [FLEX-19325] temporary fix uses custom component to set context and will be replaced by IntlProvider
//       during react-intl v2 migration refactor
class CustomIntlProvider extends React.Component<{
  locale: string;
  children: JSX.Element;
}> {
  static childContextTypes = {
    locales: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  getChildContext() {
    const { locale } = this.props;
    return { locales: [locale] };
  }

  render() {
    return <IntlShimProvider>{this.props.children}</IntlShimProvider>;
  }
}

function ReactIntlContextProvider({ reactIntlContext, children }: PropsWithChildren<{ reactIntlContext: any }>) {
  return <CustomIntlProvider {...reactIntlContext}>{children}</CustomIntlProvider>;
}

type ComposableHOC = (type: React.ComponentClass<any>) => () => JSX.Element;

const composables = {
  /**
   * Fluxible's provideContext is a higher order component that takes in a `context`
   * prop. This is a higher order component that provides the context object.
   */
  provideFluxibleContextObject: (customContext: any): ComposableHOC => {
    return (type: React.ComponentClass<any>) =>
      function FluxibleContextObjectProvider() {
        return React.createElement(type, { context: customContext });
      };
  },
  /**
   * Defer to Fluxible's provideContext, but provide a function signature that is
   * compatible with composition.
   */
  provideFluxibleContextTypes: (customContextTypes: any): ComposableHOC => {
    return (type: React.ComponentType<any>) => {
      return provideContext(type, customContextTypes);
    };
  },

  /**
   * Add a global Error Boundary to catch React errors on the client and report them.
   * https://reactjs.org/docs/error-boundaries.html
   */
  addErrorBoundary: (): ComposableHOC => {
    return (Component: React.ComponentClass<any>) => () =>
      (
        <ErrorBoundaryWithLogging>
          <Component />
        </ErrorBoundaryWithLogging>
      );
  },
};

function provideAppWithContext({
  // routing is generally () => <Router ... /> or () => <RouterContext ... /> when used in conjunction
  // with React Router.
  routing,
  fluxibleContext,
  apolloClient,
  reactIntlContext,
}: {
  routing: () => JSX.Element;
  fluxibleContext: any;
  apolloClient: ApolloClient<any>;
  reactIntlContext: { locale: string };
}): React.ComponentType<any> {
  // Apollo Client is not supported in our RequireJS stack. Handle this case
  // by using the identity if apolloClient is not available.
  const ApolloProviderOrIdentity: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) =>
    apolloClient ? (
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );

  const innerComponent = () => (
    <ApolloProviderOrIdentity>
      <AppInfoProvider>
        <ReactIntlContextProvider reactIntlContext={reactIntlContext}>
          <CdsProvider locale={reactIntlContext?.locale ?? 'en'}>
            <NaptimeContextAdapter>
              <InternetExplorerBanner Component={InternetExplorerBannerWithErrorBoundary}>
                {routing()}
              </InternetExplorerBanner>
            </NaptimeContextAdapter>
          </CdsProvider>
        </ReactIntlContextProvider>
      </AppInfoProvider>
    </ApolloProviderOrIdentity>
  );

  // These HOCs are tricker to migrate so they're left as-is for now
  return flowRight(
    composables.addErrorBoundary(),
    composables.provideFluxibleContextObject({
      fluxibleContext,
      ...fluxibleContext.getComponentContext(),
    }),
    composables.provideFluxibleContextTypes({
      fluxibleContext: PropTypes.object,
    })
  )(innerComponent);
}

const exported = {
  provideAppWithContext,
};

export default exported;
export { provideAppWithContext };
