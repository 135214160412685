import { randomUUID as uuid } from 'js/lib/uuid';

type Options = {
  uuid?: string;
};

/**
 * Class to keep track of the device.
 */
class Device {
  /**
   * The uuid of the device.
   */
  readonly uuid: string;

  constructor(options: Options = {}) {
    this.uuid = options.uuid ?? uuid();
  }

  /**
   * Serialize the device to JSON.
   */
  toJSON() {
    return { uuid: this.uuid };
  }
}

export default Device;
