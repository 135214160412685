import { getLanguageCode } from 'js/lib/language';

import _t from 'i18n!nls/page';

export const getCareerAcademyLink = (trackingParam: string) => `/career-academy/?trk_ref=${trackingParam}`;
export const getCareerAcademyName = () => _t('Find your New Career');

// The logic is copied over from static/bundles/page-header/components/CareerAcademyLink.tsx
export const getCareerAcademyLabel = () => {
  const language = getLanguageCode();
  const isEnglish = language === 'en';
  return isEnglish ? getCareerAcademyName() : _t('Find Careers');
};

export const getAdvanceYourCareerMegaMenuItemTitle = () => _t('Advance your career');
// The casing is intentional for mega menu. This is used on mobile hamburger menu as well.
export const getFindYourNewCareerMegaMenuItemTitle = () => _t('Find your new career');
