import { useQuery } from '@apollo/client';

import { buildProgramAbsoluteUrl } from 'bundles/browse/components/Domain/withPremiumProductCollections';
import type { DegreeCardDegreeProps, DegreeProductMember, Partner } from 'bundles/browse/types/degree-list';
import { getProductTypeFromTypename } from 'bundles/collections-carousel/constants';
import { buildProfessionalCertificateUrlAbsolute } from 'bundles/common/utils/urlUtils';
import getStackableDegreesPremiumProductCollections from 'bundles/front-page/queries/GetStackableDegreesForLOHP.graphql';
import getStackableSpecializationsPremiumProductCollections from 'bundles/front-page/queries/GetStackableSpecializationsForLOHP.graphql';
import type {
  GetStackableDegreesForLohpQuery,
  GetStackableDegreesForLohpQueryVariables,
  GetStackableForLohp_DegreeFragment as StackableDegree,
} from 'bundles/front-page/queries/__generated__/GetStackableDegreesForLOHP';
import type {
  GetStackableSpecializationsForLohpQuery,
  GetStackableSpecializationsForLohpQueryVariables,
  GetStackableForLohp_SpecializationFragment as StackableSpecialization,
} from 'bundles/front-page/queries/__generated__/GetStackableSpecializationsForLOHP';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';

export type PremiumProductProps = DegreeProductMember & DegreeCardDegreeProps;
type StackableTypes = StackableDegree | StackableSpecialization;

type Props = {
  skipDegree?: boolean;
  skipS12n?: boolean;
};

function filterNullOrUndefined<T>(x: T | null | undefined): x is T {
  return !!x;
}

export const getProductWithLinkAndPartners = (product: StackableTypes): PremiumProductProps => {
  let productType = getProductTypeFromTypename(product.__typename) ?? LEARNING_PRODUCTS.Degree;
  if (product.productVariant === 'PROFESSIONAL_CERTIFICATE_S12N') {
    productType = LEARNING_PRODUCTS.ProfessionalCertificate;
  }

  const primaryPartner = product.partners?.[0];
  const partners = product.partners
    ? {
        elements: product.partners.filter(filterNullOrUndefined).map((partner) => partner as Partner),
      }
    : undefined;

  if (productType === LEARNING_PRODUCTS.Specialization || productType === LEARNING_PRODUCTS.ProfessionalCertificate) {
    return {
      ...product,
      partner: primaryPartner as Partner,
      partners,
      type: productType,
      link: buildProfessionalCertificateUrlAbsolute(product.slug),
      bannerImage: (product as StackableSpecialization).cardImageUrl,
      id: `s12n~${product.id}`,
    } as PremiumProductProps;
  }

  return {
    ...product,
    marketingName: product.name,
    partner: primaryPartner as Partner,
    partners,
    type: productType,
    link: buildProgramAbsoluteUrl(product.slug, productType),
    bannerImage: (product as StackableDegree).thumbnailUrl,
    domainIds: [],
    partnerIds: [],
    id: product.id.replace(/^base~/, 'degree~'), // eventing not accept prefix with base~
  };
};

export const useStackableCollectionData = ({ skipDegree = false, skipS12n = false }: Props = {}) => {
  const { data: stackableDegreesData } = useQuery<
    GetStackableDegreesForLohpQuery,
    GetStackableDegreesForLohpQueryVariables
  >(getStackableDegreesPremiumProductCollections, {
    ssr: true,
    errorPolicy: 'all',
    context: { clientName: 'gatewayGql' },
    skip: skipDegree,
  });

  const stackableDegrees: Array<PremiumProductProps> =
    stackableDegreesData?.PremiumProductCollections?.getStackableDegrees
      ?.filter(filterNullOrUndefined)
      .slice(0, 12)
      .map((product) => getProductWithLinkAndPartners(product)) ?? [];

  const { data: stackableSpecializationsData } = useQuery<
    GetStackableSpecializationsForLohpQuery,
    GetStackableSpecializationsForLohpQueryVariables
  >(getStackableSpecializationsPremiumProductCollections, {
    ssr: true,
    errorPolicy: 'all',
    context: { clientName: 'gatewayGql' },
    skip: skipS12n,
  });

  const stackableSpecializations: Array<PremiumProductProps> =
    stackableSpecializationsData?.PremiumProductCollections?.getStackingSpecializations
      ?.filter(filterNullOrUndefined)
      .slice(0, 12)
      .map((product) => getProductWithLinkAndPartners(product)) ?? [];

  return {
    stackableDegrees,
    stackableSpecializations,
  };
};

export default useStackableCollectionData;
