import { useQuery } from '@apollo/client';
import { Specialization_DifficultyLevel as SpecializationDifficultyLevel } from '__generated__/graphql-types';

import CareerRolesCollectionQuery from 'bundles/unified-common/queries/CareerRolesCollection.graphql';
import type {
  CareerRoleFragment,
  CareerRolesCollectionFragment,
  CareerRolesCollectionQueryQuery as CareerRolesCollectionQueryType,
  PathwaySpecializationFragment,
  PathwaysFragment,
} from 'bundles/unified-common/queries/__generated__/CareerRolesCollection';

export type CareerRoleType = Omit<CareerRoleFragment, 'pathways'> & {
  pathways: Omit<PathwaysFragment, 'entities'> & {
    entities?: Array<PathwaySpecializationFragment>;
  };
};

export type CareerRolesCollectionType = Omit<CareerRolesCollectionFragment, 'entities'> & {
  entities?: Array<CareerRoleType>;
};

export const normalizeCareerRolesCollection = (
  content?: CareerRolesCollectionFragment
): CareerRolesCollectionType | undefined => {
  if (!content) {
    return undefined;
  }

  const collection = {
    ...content,
    entities: content?.entities?.elements
      .map((entity) => {
        if (!entity || entity.__typename !== 'Skill_CareerRole') {
          return null;
        }

        const pathways = {
          ...entity?.pathways,
          entities: entity?.pathways?.entities?.elements
            .map((pathway) => {
              if (
                pathway?.__typename !== 'ProductCard_ProductCard' ||
                pathway?.productTypeAttributes?.__typename !== 'ProductCard_Specialization'
              ) {
                return null;
              }

              const product = pathway?.productTypeAttributes?.canonical;

              const proficiencyLevel = pathway?.productTypeAttributes?.isPathwayContent
                ? SpecializationDifficultyLevel.Beginner
                : product?.difficultyLevel;

              return {
                ...product,
                proficiencyLevel,
              };
            })
            .filter(Boolean),
        };

        return {
          ...entity,
          pathways,
        };
      })
      .filter(Boolean),
  };

  return collection;
};

function useCareerRolesCollection({ ssr, skip, limit = 12 }: { ssr: boolean; skip: boolean; limit?: number }) {
  const { data, loading } = useQuery<CareerRolesCollectionQueryType>(CareerRolesCollectionQuery, {
    variables: {
      queryCollectionId: 'role-ranker',
      limit,
    },
    notifyOnNetworkStatusChange: true,
    ssr,
    skip,
    errorPolicy: 'all' as const,
    context: { clientName: 'gatewayGql' },
  });

  return {
    data: normalizeCareerRolesCollection(data?.Collection?.queryCollection),
    loading,
  };
}

export default useCareerRolesCollection;
