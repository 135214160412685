import localStorage from 'bundles/common/utils/localStorageEx';

import BaseStore from './base';

/**
 * Name of the index key.
 */
const INDEX = '__index__';

class LocalStorageStore extends BaseStore {
  static prefix = 'tracking';

  constructor() {
    if (!localStorage.isAvailable()) {
      throw new Error('[LocalStorageStore] LocalStorage is not available');
    }

    super();
  }

  /**
   * Clear the store by removing all keys. These keys are stored in the index.
   */
  clear(): void {
    const index = this.get<string[]>(INDEX) ?? [];

    index.forEach((key) => this.remove(key));
  }

  /**
   * Get the value for the given key.
   *
   * @param key
   */
  get<TData>(key: string): TData | undefined {
    return localStorage.getItem(`${LocalStorageStore.prefix}.${key}`, JSON.parse, undefined);
  }

  /**
   * Set the value for the given key. Store the key in the index.
   *
   * @param key
   * @param value
   */
  set<TData>(key: string, value: TData): void {
    const index = this.get<string[]>(INDEX) ?? [];

    localStorage.setItem(`${LocalStorageStore.prefix}.${INDEX}`, [...new Set([...index, key])], JSON.stringify);
    localStorage.setItem(`${LocalStorageStore.prefix}.${key}`, value, JSON.stringify);
  }

  /**
   * Remove the value for the given key. Remove the key from the index.
   *
   * @param key
   */
  remove(key: string): void {
    const index = this.get<string[]>(INDEX) ?? [];

    localStorage.setItem(
      `${LocalStorageStore.prefix}.${INDEX}`,
      index.filter((item) => item !== key),
      JSON.stringify
    );
    localStorage.removeItem(`${LocalStorageStore.prefix}.${key}`);
  }
}

export default LocalStorageStore;
