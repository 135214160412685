import React from 'react';

import { capitalize } from '@material-ui/core/utils';

import clsx from 'clsx';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import typographyCss, {
  classes,
  typography,
  typographyColor,
} from './typographyCss';

export type TypographyVariant =
  | 'display'
  | 'titleLarge'
  | 'titleMedium'
  | 'titleSmall'
  | 'subtitleLarge'
  | 'subtitleMedium'
  | 'bodyPrimary'
  | 'bodySecondary'
  | 'actionPrimary'
  | 'actionSecondary'
  | 'inherit';

type BaseProps = {
  /**
   * Additional classes to apply to the root element.
   */
  className?: string;
  /**
   * Text content to apply typography styles to.
   */
  children?: React.ReactNode;

  /**
   * Set the text-align on the component.
   * @default inherit
   **/
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';

  /**
   * Controls the display type.
   * @default initial
   **/
  display?: 'initial' | 'block' | 'inline';

  /**
   * Applies the theme typography styles.
   * @default bodyPrimary
   **/
  variant?: TypographyVariant;

  /**
   * Specify a semantic html tag to use. Semantically correct tags are important for accessibility.
   * For example: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'section', etc.
   */
  component: React.ElementType;

  /**
   * The color for the text. It supports the theme colors relevant for this component.
   * @default body
   */
  color?:
    | 'body'
    | 'invertBody'
    | 'supportText'
    | 'primaryHeadline'
    | 'error'
    | 'success'
    | 'highlightBlue'
    | 'highlightPurple'
    | 'eyebrowYellow'
    | 'eyebrowAqua'
    | 'eyebrowPurple'
    | 'inherit';
};

export interface TypographyTypeMap<D extends React.ElementType = 'p'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = TypographyTypeMap['defaultComponent']
> = OverrideProps<TypographyTypeMap<D>, D> & {
  /**
   * @default p
   */
  component?: React.ElementType;
};

/**
 * Use to display headings and copy text
 *
 * See [Props](__storybookUrl__/foundation-typography--default#props)
 */
const Typography: OverridableComponent<TypographyTypeMap> = React.forwardRef<
  HTMLSpanElement,
  Props
>(function Typography(props, ref) {
  const {
    className,
    color = 'body',
    variant = 'bodyPrimary',
    component: Component,
    display = 'initial',
    align = 'inherit',
    ...rest
  } = props;

  return (
    <Component
      ref={ref}
      className={clsx(className, {
        [classes[`align${capitalize(align)}` as keyof typeof classes]]:
          align !== 'inherit',
        [classes[`display${capitalize(display)}` as keyof typeof classes]]:
          display !== 'initial',
      })}
      css={[typographyCss, typography[variant], typographyColor[color]]}
      {...rest}
    />
  );
});

export default Typography;
