import _t from 'i18n!nls/front-page';

export const getGenAICollectionSets = () => {
  return [
    {
      id: 'genai-hub-genai-for-beginners',
      context: 'CATEGORY',
      contextId: 'genai-hub-genai-for-beginners',
      filterName: _t('Beginner'),
    },
    {
      id: 'genai-hub-popular-ai-programs',
      context: 'CATEGORY',
      contextId: 'genai-hub-popular-ai-programs',
      filterName: _t('Popular'),
    },
    {
      id: 'new-generative-ai-courses',
      context: 'CATEGORY',
      contextId: 'new-generative-ai-courses',
      filterName: _t('New'),
    },
    {
      id: 'genai-hub-genai-creation-tools',
      context: 'CATEGORY',
      contextId: 'genai-hub-genai-creation-tools',
      filterName: _t('Tools'),
    },
  ];
};
