/**
 * Base class for all stores.
 */
abstract class BaseStore {
  /**
   * Clear the store.
   */
  abstract clear(): void;

  /**
   * Get the value for the given key.
   *
   * @param key
   */
  abstract get<TData>(key: string): TData | undefined;

  /**
   * Set the value for the given key.
   *
   * @param key
   * @param value
   */
  abstract set<TData>(key: string, value: TData): void;

  /**
   * Remove the value for the given key.
   *
   * @param key
   */
  abstract remove(key: string): void;
}

export default BaseStore;
