import { createContext, useCallback, useContext } from 'react';
import type { RouterState } from 'react-router';
import type { MatchState } from 'react-router/lib/match';

import type { InjectedRouter } from 'js/lib/connectToRouter';

type History = MatchState['history'];
export type Location = MatchState['location'];

export type OldRouterNewContextValue = {
  history?: History;
  location?: Location;
  router?: InjectedRouter;
};

type Params = RouterState['params'];

type Routes = InjectedRouter['routes'];

interface NavigateOptions {
  replace?: boolean;
  state?: any;
}

/**
 * The interface for the navigate() function returned from useNavigate().
 */
export interface NavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

interface Path {
  /**
   * A URL pathname, beginning with a /.
   */
  pathname: string;

  /**
   * A URL search string, beginning with a ?.
   */
  search: string;

  /**
   * A URL fragment identifier, beginning with a #.
   */
  hash: string;
}

/**
 * Describes a location that is the destination of some navigation, either via
 * `history.push` or `history.replace`. May be either a URL or the pieces of a
 * URL path.
 */
export type To = string | Partial<Path>;

export const OldRouterNewContext = createContext<OldRouterNewContextValue>({});

/**
 * This Hook returns the router object from the React Router context. Sample usage:
 *
 * ```
 * const { push } = useRouter();
 * ```
 *
 * To aid in the transition from the `connectToRouter` HOC, `useRoute` takes an optional callback that is used to map
 * the router to whatever you want the Hook to return, e.g.
 *
 * ```
 * const { discussionPage, itemId } = useRouter((router) => ({
 *   discussionPage: parseInt(router.location.query.page, 10) || 1,
 *   itemId: router.params.itemId,
 * }));
 * ```
 * @deprecated use `useNavigate`, `useLocation`, `useRoutes` and `useParams` instead
 *
 * @return {InjectedRouter} - the router object
 */
export function useRouter<RouterProps = InjectedRouter>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPropsFromRouter: (router: InjectedRouter) => RouterProps = (r) => r as any
) {
  const { router } = useContext(OldRouterNewContext);

  if (!router) {
    throw new Error('useRouter cannot find router; did you forget to add an OldRouterNewContext.Provider?');
  }

  return getPropsFromRouter(router);
}

/**
 * This Hook returns the `location` object that represents the current URL. You can think about it like a `useState`
 * that returns a new location whenever the URL changes.
 * @return {Location} - the location object
 */
export function useLocation(): Location {
  return useRouter().location;
}

/**
 * This Hook returns a function that you can call to navigate programmatically.
 *
 * @return {function} - a function that you can call to navigate programmatically
 */
export function useNavigate(): NavigateFunction {
  const { history } = useContext(OldRouterNewContext);

  if (!history) {
    throw new Error('useNavigate cannot find history; did you forget to add an OldRouterNewContext.Provider?');
  }

  const { go, push, replace } = history;

  return useCallback<NavigateFunction>(
    (to, options: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        go(to);
      } else {
        const method = options.replace ? replace : push;
        let target;
        if (typeof to === 'string') {
          const [pathname, search] = to.split('?');
          target = { pathname, search: search ? `?${search}` : undefined };
        } else {
          target = to;
        }
        method({ ...target, state: options.state });
      }
    },
    [go, push, replace]
  );
}

/**
 * This Hook returns an object of key/value pairs of URL parameters. Use it to access `match.params` of the current
 * `<Route>`.
 * @return {object} - an object where the key is the URL placeholder name and the value is the URL fragment string
 */
export function useParams(): Params {
  const router = useRouter();

  if (!router) {
    throw new Error('useParams cannot find router; did you forget to add an OldRouterNewContext.Provider?');
  }

  return router.params;
}

/**
 * This Hook returns an array of routes (PatchedPlainRoutes).
 *
 * @return {Routes} - A route object
 */
export function useRoutes(): Routes {
  return useRouter().routes;
}

export default useRouter;
