import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Tooltip', [
  'tooltip',
  'arrow',
  'inverted',
  'placementBottom',
  'placementTop',
]);

const styles = css`
  --tooltipBackground: var(--cds-color-callouts-background-primary-strong);
  --tooltipTextColor: var(--cds-color-neutral-primary-invert);

  .${classes.tooltip} {
    ${typography.bodySecondary};
    max-width: 200px;
    background: var(--tooltipBackground);
    color: var(--tooltipTextColor);

    &.${classes.placementBottom} {
      margin: 13px 0;

      .${classes.arrow} {
        margin-top: -8px;

        &::before {
          /*! @noflip */
          margin-left: 5px;
        }
      }
    }

    &.${classes.placementTop} {
      margin: 13px 0;

      .${classes.arrow} {
        margin-bottom: -8px;

        &::before {
          /*! @noflip */
          margin-left: -5px;
        }
      }
    }
  }

  .${classes.arrow} {
    color: var(--tooltipBackground);
    width: 20px;
    height: 8px;

    &::before {
      /*! @noflip */
      border-top-left-radius: 3px;

      /*! @noflip */
      border-bottom-right-radius: 3px;
    }
  }

  &.${classes.inverted} {
    --tooltipBackground: var(--cds-color-neutral-primary-invert);
    --tooltipTextColor: var(--cds-color-neutral-primary);
  }
`;

export default styles;
