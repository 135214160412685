import * as Sentry from '@sentry/react';
import pLimit from 'p-limit';

import logger from 'js/app/loggerSingleton';

import type Envelope from '../envelope';
import { createDefaultStore } from '../store';
import type { Store } from '../store';
import BaseTransport from './base';
import type { Options as BaseOptions } from './base';

type OwnOptions = {
  store: Store;
};

type Options = BaseOptions & OwnOptions;

/**
 * The time in milliseconds to wait before sending the batch. If the
 * browser is idle, the batch will be sent immediately.
 */
const BATCH_TIMEOUT = 300;
/**
 * The time in milliseconds to wait before retrying a batch request.
 */
const BATCH_INCREMENT = 10;
/**
 * The maximum number of events to send in a single batch request.
 */
const BATCH_MAX_SIZE = 50;

const limit = pLimit(1);

/**
 * Transport that uses the Fetch API to send events. It batches events
 * and sends them in a single request.
 */
class FetchTransport extends BaseTransport {
  /**
   * Enable/disables the transport and prevents it from sending events.
   *
   * In case the transport fails to send events, it will be disable for queueing events.
   */
  static enable = true;

  /**
   * The exponential backoff for the batch requests.
   *
   * In case of failure, the next request will be delayed by
   * `BATCH_INCREMENT * 2 ** exponential` milliseconds.
   */
  static exponential = 0;

  /**
   * If the transport needs to store events before sending them, it will use
   * this store.
   *
   * @protected
   */
  private store: Store;

  constructor(options: Options) {
    super(options);

    this.store = options.store ?? createDefaultStore();

    FetchTransport.exponential = this.store.get<number>('exponential') ?? 0;
  }

  /**
   * Send data to the server.
   *
   * @param data
   */
  async request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    const response = await fetch(this.batchUrl, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (response.status !== 200) {
      throw new Error(`[FetchTransport]: ${response.status} ${response.statusText}`);
    }

    return response;
  }

  /**
   * Send the batch of events to the server.
   */
  private poll() {
    // Limit the concurrent requests to 1
    return limit(async () => {
      try {
        const queue = this.store.get<ReturnType<Envelope['toJSON']>[]>('batch') ?? [];

        if (!queue.length) return;

        const peak = queue[0];
        // Figure out what happens if events application are different
        const payload = {
          app: peak.app,
          clientType: peak.clientType,
          clientVersion: peak.clientVersion,
          events: queue.slice(0, BATCH_MAX_SIZE),
        };

        let response;
        try {
          response = await this.request(payload);
        } catch (e) {
          // Increment the exponential backoff
          FetchTransport.exponential += 1;
          // Store the exponential when navigating between pages
          this.store.set<number>('exponential', FetchTransport.exponential);

          const DELAY = BATCH_INCREMENT * 2 ** FetchTransport.exponential;

          // We need to bind the poll method
          setTimeout(() => this.poll(), DELAY);

          return;
        }

        // Request was successful
        const data = await response?.json();

        if (data['400']) {
          // If there are any 400 errors, we just post them in the console
          logger.warn('[Eventing]: Some event failed validation', data['400']);
          Sentry.captureMessage('[Eventing]: Some event failed validation', { extra: { response: data } });
        }

        this.store.set('batch', []);

        // Reset the exponential backoff
        FetchTransport.exponential = 0;
      } catch (e) {
        // Disable the transport
        FetchTransport.enable = false;

        // Something went really wrong. Let's drop the store used for this transport
        this.store.remove('batch');
        this.store.remove('exponential');

        // Error to be caught by Sentry
        Sentry.captureException(e);
      }
    });
  }

  /**
   * Store the event in the queue and schedule a batch request.
   *
   * @override
   * @param envelope
   */
  async send(envelope: Envelope): Promise<void> {
    try {
      if (!FetchTransport.enable) {
        // We throw in here to prevent the event from being stored
        throw new Error('[FetchTransport]: Transport is disabled');
      }

      await limit(() => {
        const previous = this.store.get<Envelope[]>('batch') ?? [];

        this.store.set('batch', [...previous, envelope.toJSON()]);

        // We need to bind the poll method
        requestIdleCallback(() => this.poll(), { timeout: BATCH_TIMEOUT });
      });
    } catch {
      throw new Error('[FetchTransport]: Failed to enqueue the event');
    }
  }
}

export default FetchTransport;
