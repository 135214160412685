/* eslint-disable camelcase */
import type { Client, Events, Plugin } from '@coursera/event-pulse/core';

import localStorageEx from 'bundles/common/utils/localStorageEx';

const EMPTY = 'EMPTY';
const MAPPER: Record<string, string> = {
  campaignid: 'semCampaignId',
  creativeid: 'semCreativeId',
  keyword: 'semKeyword',
};
const PARAMETERS = [
  'campaignid',
  'creativeid',
  'keyword',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
] as const;
const UNSET = '-';

type Campaign = Record<(typeof PARAMETERS)[number], string>;

/**
 * Get campaign parameters from the URL.
 */
function getCampaignParameters(): Campaign {
  const searchParams = new URLSearchParams(window.location.search);

  return PARAMETERS.reduce((campaign, param) => {
    const value = searchParams.get(param);

    return { ...campaign, [param]: value };
  }, {} as Campaign);
}

function isDirectTraffic(campaign: Campaign): boolean {
  return PARAMETERS.every((param) => !campaign[param]);
}

function isNewCampaign(a: Campaign, b: Campaign): boolean {
  return PARAMETERS.some((param) => a[param] !== b[param]);
}

function clearPreviousCampaign() {
  localStorageEx.removeItem('__analytics_campaign__');
}

/**
 * This hook is called when a new session starts and sends an identify event with the first touch values.
 * It's important to note that this event is sent only once per session.
 * The purpose of this event is to store the first touch values in the user profile.
 *
 * @param client
 */
function trackInitialCampaignFactory(client: Client) {
  return async () => {
    const parameters = getCampaignParameters();

    // We cannot rely on `identify` function as it's using high client to send the events
    await client.sendEvent('identify', {
      setOnce: {
        initialSemCampaignId: parameters.campaignid || EMPTY,
        initialSemCreativeId: parameters.creativeid || EMPTY,
        initialSemKeyword: parameters.keyword || EMPTY,
        initial_referrer_url: window.document.referrer || EMPTY,
        initial_utm_campaign: parameters.utm_campaign || EMPTY,
        initial_utm_content: parameters.utm_content || EMPTY,
        initial_utm_medium: parameters.utm_medium || EMPTY,
        initial_utm_source: parameters.utm_source || EMPTY,
        initial_utm_term: parameters.utm_term || EMPTY,
      },
    });
  };
}

/**
 * This hook is called when session expires or page view event is triggered and
 * sends an identify event with the current campaign parameters.
 *
 * @param client
 * @param isNewSession
 */
function trackCampaignFactory(client: Client, isNewSession: boolean = false) {
  return async () => {
    const current = getCampaignParameters();
    const previous: Campaign = localStorageEx.getItem('__analytics_campaign__', JSON.parse, {}, {});

    // There are two ways to track a campaign
    // 1. We detect a new campaign
    // 2. At the beginning of the session (even if it is a direct traffic)
    if (!isNewCampaign(current, previous) || (isDirectTraffic(current) && !isNewSession)) {
      return;
    }

    localStorageEx.setItem('__analytics_campaign__', current, JSON.stringify);

    const values = [...Object.entries(current), ['referrer_url', window.document.referrer]].reduce(
      (acc, [key, value]) => {
        return value
          ? { set: { ...acc.set, [MAPPER[key] ?? key]: value }, unset: acc.unset }
          : { set: acc.set, unset: { ...acc.unset, [MAPPER[key] ?? key]: UNSET } };
      },
      { set: {}, unset: {} } as Events['identify']
    );

    // We cannot rely on `identify` function as it's using high client to send the events
    await client.sendEvent('identify', values);
  };
}

/**
 * This hook is called before page view event and detects if there are any new campaign parameters
 * in the URL. If there are, it sends an identify event with the new campaign parameters.
 *
 * @param client
 */
function trackCampaignOnViewPageFactory(client: Client) {
  const trackCampaign = trackCampaignFactory(client);

  return async (eventName: keyof Events) => {
    if (eventName === 'view_page') {
      await trackCampaign();
    }
  };
}

const plugin: Plugin = (client) => {
  client.on('beforeSend', trackCampaignOnViewPageFactory(client));
  client.on('sessionStart', clearPreviousCampaign);
  client.on('sessionStart', trackInitialCampaignFactory(client));
  client.on('sessionStart', trackCampaignFactory(client, true));
};

export type { Campaign };
export { getCampaignParameters, plugin };
