/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';
import { isUserRightToLeft } from 'js/lib/language';

import { ChevronNextIcon } from '@coursera/cds-icons';
import { track } from '@coursera/event-pulse/sdk';

import A11yScreenReaderOnly from 'bundles/a11y/components/A11yScreenReaderOnly';
import { getFindYourNewCareer, getFreeItem } from 'bundles/browse/components/PageHeader/constants';
import styles from 'bundles/megamenu/components/__styles__/MegaMenuMain';
import type { MenuItem } from 'bundles/megamenu/types/MenuData';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

type Props = {
  item: MenuItem;
  selected: boolean;
  closeMenu: () => void;
  selectedDomain?: MenuItem;
  setCursorDomain: (domain: MenuItem) => void;
  selectMenuItem: () => void;
  isFirst: boolean;
  focusPreviousMenuItem: () => void;
  focusNextMenuItem: () => void;
  href?: string | null;
  sectionName?: string;
  renderItemContent?: () => React.ReactNode;
  setIsUsingArrowKeys: () => void;
};

type State = {
  isFocusable: boolean;
};

const svgChevronRightStyle = { position: 'absolute' as const, right: 20, top: 5 };
const svgChevronLeftStyle = { position: 'absolute' as const, left: 20, top: 5 };

class MegaMenuMainItem extends React.Component<Props, State> {
  state = {
    isFocusable: this.props.isFirst,
  };

  focusOnFirstMenuItem = () => {
    window.setTimeout(() => {
      const firstMenuItem = document.querySelector('.rc-MegaMenuSubPanel .active .rc-MegaMenuSection a') as HTMLElement;
      firstMenuItem?.focus();
    }, 0);
  };

  onMouseEnterHandler = () => {
    const { setCursorDomain, item, selectedDomain, selectMenuItem } = this.props;
    setCursorDomain(item);
    if (!selectedDomain) selectMenuItem();
  };

  onClickHandler = (event: React.MouseEvent | React.KeyboardEvent) => {
    const { href, selectMenuItem, item } = this.props;
    const hasSubmenu = this.getHasSubMenu();
    event.preventDefault();
    if (hasSubmenu) {
      selectMenuItem();
      this.focusOnFirstMenuItem();
    } else if (href) {
      track('click_megamenu_item', {
        megamenuItemName: item.id ?? '',
        megamenuSection: 'goals',
        megamenuItemType: 'other',
        megamenuItemLink: href,
      });
      redirect.setLocation(href);
    }
  };

  onKeyDownHandler = (event: React.KeyboardEvent) => {
    const { closeMenu, focusPreviousMenuItem, focusNextMenuItem, setIsUsingArrowKeys } = this.props;

    event.stopPropagation();
    setIsUsingArrowKeys();

    switch (event.key) {
      case ' ':
      case 'Enter':
      case isUserRightToLeft() ? 'ArrowLeft' : 'ArrowRight':
        event.preventDefault();
        this.onClickHandler(event);
        break;
      case 'Escape':
        closeMenu();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.setState({ isFocusable: false });
        focusNextMenuItem();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.setState({ isFocusable: false });
        focusPreviousMenuItem();
        break;
      default:
    }
  };

  onFocusHandler = () => {
    this.setState({ isFocusable: true });
  };

  preventDefault = (event: React.MouseEvent) => event.preventDefault();

  getHasSubMenu = () => {
    const { item } = this.props;
    return ![getFreeItem().id, getFindYourNewCareer().id].includes(item.id ?? '');
  };

  renderSubMenuIndicator = () => {
    const { selected, item, sectionName } = this.props;
    const isRTL = isUserRightToLeft();
    const chevronStyle = isRTL ? svgChevronLeftStyle : svgChevronRightStyle;
    return (
      <>
        <ChevronNextIcon
          size="small"
          style={chevronStyle}
          color={selected ? 'interactive' : 'default'}
          aria-hidden="true"
        />
        <A11yScreenReaderOnly tagName="span" id={`${item.id}~description`}>
          {_t('menu. #{sectionName} section. Press the #{arrowDirection} arrow key to expand submenu', {
            sectionName,
            arrowDirection: isUserRightToLeft() ? _t('left') : _t('right'),
          })}
        </A11yScreenReaderOnly>
      </>
    );
  };

  render() {
    const { selected, item, href, renderItemContent } = this.props;
    const { isFocusable } = this.state;

    const hasSubmenu = this.getHasSubMenu();
    const tabIndex = selected || isFocusable ? 0 : -1;

    const itemContent = renderItemContent ? (
      renderItemContent()
    ) : (
      <FormattedMessage message={_t('{itemName}')} itemName={item.menuName} />
    );

    // Using the `onMouseUp` attribute instead of `onClick` seeing that hitting enter/space on a
    // focused Button that has an onClick attribute will fire that onClick attribute. Thankfully,
    // that's still getting fired, executing the tracking code, but the new attributes allow us
    // to segment the interaction based off of source.
    const tagProps = {
      className: 'rc-MegaMenuMainItem',
      css: [styles.menuItem, selected && styles.menuItemSelected],
      trackingName: 'mega_menu_main_item',
      data: { menuName: item.menuName, id: item.id, slug: item.slug, link: item.link },
      id: `${item.id}~menu-item`,
      onClick: this.preventDefault,
      onMouseUp: this.onClickHandler,
      onMouseDown: this.preventDefault,
      onMouseEnter: this.onMouseEnterHandler,
      onKeyDown: this.onKeyDownHandler,
      onFocus: this.onFocusHandler,
      'aria-expanded': !hasSubmenu ? undefined : selected && hasSubmenu,
      'aria-haspopup': hasSubmenu,
      tabIndex,
      'aria-level': 1,
      'data-e2e': `megamenu-item~${item.id}`,
    };

    if (href) {
      return (
        <TrackedA {...tagProps}>
          {itemContent}
          {hasSubmenu && this.renderSubMenuIndicator()}
        </TrackedA>
      );
    } else {
      return (
        <TrackedButton {...tagProps}>
          {itemContent}
          {hasSubmenu && this.renderSubMenuIndicator()}
        </TrackedButton>
      );
    }
  }
}

export default MegaMenuMainItem;
