import React from 'react';

import { Typography as MuiTypography } from '@material-ui/core';

import clsx from 'clsx';

import type {
  OverrideProps,
  OverridableComponent,
  TypographyVariant,
} from '@coursera/cds-common';

import getTypographyCss, { classes } from './getTypographyCss';

type BaseProps = {
  /**
   * Set the text-align on the component.
   * @default inherit
   **/
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';

  /**
   * Controls the display type.
   * @default initial
   **/
  display?: 'initial' | 'block' | 'inline';

  /**
   * Applies the theme typography styles and associated HTML element.
   * For example: variant h1 will render a h1 HTML element unless overriden by a component prop.
   * @default body1
   **/
  variant?: TypographyVariant;

  /**
   * The color for the text. It supports the theme colors relevant for this component.
   * @default body
   */
  color?:
    | 'body'
    | 'invertBody'
    | 'supportText'
    | 'primaryHeadline'
    | 'error'
    | 'success'
    | 'highlightBlue'
    | 'highlightPurple'
    | 'eyebrowYellow'
    | 'eyebrowAqua'
    | 'eyebrowPurple'
    | 'inherit';
};

export interface TypographyTypeMap<D extends React.ElementType = 'p'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = TypographyTypeMap['defaultComponent']
> = OverrideProps<TypographyTypeMap<D>, D> & {
  /**
   * @default p
   */
  component?: React.ElementType;
};

const variantMapping: Record<TypographyVariant, React.ElementType> = {
  d1: 'h1',
  d1semibold: 'h1',
  d2: 'h2',
  d2semibold: 'h2',
  h1: 'h1',
  h1semibold: 'h1',
  h2: 'h2',
  h2semibold: 'h2',
  h3semibold: 'h3',
  h3bold: 'h3',
  h4bold: 'h4',
  body1: 'p',
  body2: 'p',
  inherit: 'p',
};

/**
 * Use to display headings and copy text
 *
 * See [Props](__storybookUrl__/product-guidelines-engineering-typography-deprecated--default#props)
 *
 * @deprecated Please use Typography2 component instead. It supports new variants introduced in CDS 2.0
 */
const Typography: OverridableComponent<TypographyTypeMap> = React.forwardRef(
  function Typography(props: Props, ref: React.Ref<HTMLSpanElement>) {
    const css = getTypographyCss(props);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { color, variant = 'body1', component, ...rest } = props;

    return (
      <MuiTypography
        ref={ref}
        className={clsx(rest.className, {
          [classes.base]: true,
        })}
        component={component || variantMapping[variant]}
        css={css}
        {...rest}
      />
    );
  }
);

Typography.defaultProps = {
  color: 'body',
};

export default Typography;
