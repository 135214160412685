import { randomUUID as uuid } from 'js/lib/uuid';

type Options = {
  history?: [referrer: string, current: string] | [initialReferrer: string, referrer: string, current: string];
  lastActivity?: number;
  sequence?: number;
  ttl?: number;
  uuid?: string;
};

const DEFAULT_TTL = 30 * 60 * 1000; // 30 minutes

/**
 * Class to keep track of the session.
 */
class Session {
  /**
   * Represents the URL history of the session. We use it to track the referrer and initial referrer.
   * @private
   */
  private history: [referrer: string, current: string] | [initialReferrer: string, referrer: string, current: string];

  /**
   * The last activity of the session.
   * @private
   */
  private lastActivity: number;

  /**
   * Number representing the sequence of the events sent in the session.
   * @private
   */
  private sequence: number;

  /**
   * The time to live of the session.
   * @private
   */
  private readonly ttl: number;

  /**
   * The uuid of the session.
   */
  uuid: string;

  constructor(options: Options = {}) {
    // If the history already exists, and we try to initialize the session again
    // it means we are visiting a different page so we keep the initial referrer and start a new history
    this.history = options.history ?? [window.document.referrer, window.location.href];
    this.lastActivity = options.lastActivity ?? Date.now();
    this.sequence = options.sequence ?? 0;
    this.ttl = options.ttl ?? DEFAULT_TTL;
    // Amplitude needs ~timestamp suffix for a lot of calculations.
    // CRITICAL to keep this format.
    this.uuid = options.uuid ?? `${uuid()}~${Date.now()}`;
  }

  /**
   * The initial referrer of the session.
   */
  get initialReferrer(): string {
    return this.history[0];
  }

  /**
   * The current referrer of the session.
   */
  get referrer(): string {
    return this.history[this.history.length - 2];
  }

  /**
   * The current URL of the session.
   */
  get url(): string {
    return this.history[this.history.length - 1];
  }

  /**
   * Check if the session is expired.
   */
  isExpired(): boolean {
    return Date.now() - this.lastActivity > this.ttl;
  }

  /**
   * Check if the session is new.
   */
  isNew(): boolean {
    return this.sequence === 0;
  }

  /**
   * Get the next sequence number.
   */
  getSequence(): number {
    // Increase the sequence number
    this.sequence += 1;

    return this.sequence;
  }

  /**
   * Updates the history of the session with the current URL.
   * @param url
   */
  navigate(url: string): void {
    if (this.history[this.history.length - 1] !== url) {
      // We only keep the last two URLs and the initial URL to track the referrer and initial referrer
      this.history = [this.history[0], this.history[this.history.length - 1], url];
      //              ^ initial ref    ^ previous URL ( last history item )   ^ current URL
    }
  }

  /**
   * Serialize the session.
   */
  toJSON(): Omit<Options, 'history'> {
    // We do not store the history because we only use it for client-side navigations.
    // Once the user navigates to a new page, the history is reset.
    return {
      lastActivity: this.lastActivity,
      sequence: this.sequence,
      ttl: this.ttl,
      uuid: this.uuid,
    };
  }

  /**
   * Updates last activity
   */
  touch(): void {
    this.lastActivity = Date.now();
  }
}

export type { Options };
export { DEFAULT_TTL };
export default Session;
