import type Envelope from '../envelope';

type Options = {
  batchUrl?: string;
  url?: string;
};

const DEFAULT_BATCH_URL = '/api/rest/v1/eventing/infobatch';
const DEFAULT_URL = '/api/rest/v1/eventing/info';

/**
 * Base class for all transports.
 */
abstract class BaseTransport {
  /**
   * The URL to send batched events to.
   *
   * @protected
   */
  protected batchUrl: string;

  /**
   * The URL to send events to.
   *
   * @protected
   */
  protected url: string;

  constructor(options: Options = {}) {
    this.batchUrl = options.batchUrl ?? DEFAULT_BATCH_URL;
    this.url = options.url ?? DEFAULT_URL;
  }

  /**
   * Send the batch of events to the server.
   *
   * @param data
   */
  abstract request<TData extends Record<string, unknown>>(data: TData): Promise<Response>;

  /**
   * Unwrap the envelope and request the event to the server.
   *
   * @param envelope
   */
  async send(envelope: Envelope): Promise<void> {
    await this.request(envelope.toJSON());
  }
}

export type { Options };
export { DEFAULT_BATCH_URL, DEFAULT_URL };
export default BaseTransport;
