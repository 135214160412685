import * as React from 'react';
import type { Router } from 'react-router';
import { Route as NativeRoute } from 'react-router';

import type { Location } from 'history';

import type { PageViewOptions } from '@coursera/event-pulse/sdk';

type OwnProps = {
  // need to import named route props :\
  name?: string;
  handle: {
    tracking: {
      /**
       * A function that returns aditional information to the page view event.
       * This function is called with the current route params and location.
       *
       * @param params
       * @param location
       */
      data?: (params: Router.Params, location: Location) => Partial<PageViewOptions>;
      defaultSegment?: PageViewOptions['page']['segment'];
      segment?: PageViewOptions['page']['segment'];
    };
    [key: string]: any;
  };
  status?: any;
};
type Props = Omit<NativeRoute.RouteProps, keyof OwnProps> & OwnProps;

const Route = ({ ref, ...props }: Props) => <NativeRoute {...props} />;

export * from 'react-router';
export { Route };
