import { memoize } from 'lodash';

import type { Options as SessionOptions } from './session';
import Session, { DEFAULT_TTL } from './session';
import { createDefaultStore } from './store';
import type { Store } from './store';

type Config = Pick<SessionOptions, 'ttl'>;

type OwnOptions = {
  store: Store;
};

type Options = Config & OwnOptions;

class SessionProvider {
  private readonly config: Config;

  private instance: Session;

  private store: Store;

  constructor(options: Options) {
    this.config = { ttl: options.ttl ?? DEFAULT_TTL };
    this.store = options.store;

    // Restore the previous session if any
    const previous = this.store.get<Partial<Session>>('session');

    this.instance = new Session({
      lastActivity: -(options.ttl ?? DEFAULT_TTL),
      ttl: options.ttl,
      ...previous,
    });
  }

  get(): Session {
    return this.instance;
  }

  reset(): void {
    this.instance = new Session({
      history: [this.instance.referrer, this.instance.url],
      ttl: this.config.ttl,
    });
  }

  touch(): void {
    // Update the history of the navigation
    this.instance.navigate(window.location.href);

    // Update the last interaction
    this.instance.touch();

    // Record the state of the session
    this.store.set('session', this.instance.toJSON());
  }
}

const createDefaultSessionProvider = memoize(() => new SessionProvider({ store: createDefaultStore() }));

export { createDefaultSessionProvider };
export default SessionProvider;
