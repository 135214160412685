import cookie from 'js/lib/cookie';

import BaseStore from './base';

/**
 * Name of the index key.
 */
const INDEX = '__index__';

class CookieStore extends BaseStore {
  static prefix = 'tracking';

  /**
   * Clear the store by removing all keys. These keys are stored in the index.
   */
  clear(): void {
    const index = this.get<string[]>(INDEX) ?? [];

    index.forEach((key) => this.remove(key));
  }

  /**
   * Get the value for the given key.
   *
   * @param key
   */
  get<TData>(key: string): TData | undefined {
    try {
      return JSON.parse(cookie.get(`${CookieStore.prefix}.${key}`) ?? 'undefined');
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Set the value for the given key. Store the key in the index.
   *
   * @param key
   * @param value
   */
  set<TData>(key: string, value: TData): void {
    const index = this.get<string[]>(INDEX) ?? [];

    cookie.set(`${CookieStore.prefix}.${INDEX}`, JSON.stringify([...index, key]), { days: 365 });
    cookie.set(`${CookieStore.prefix}.${key}`, value, { days: 365 });
  }

  /**
   * Remove the value for the given key. Remove the key from the index.
   *
   * @param key
   */
  remove(key: string): void {
    const index = this.get<string[]>(INDEX) ?? [];

    cookie.set(`${CookieStore.prefix}.${INDEX}`, JSON.stringify(index.filter((item) => item !== key)), { days: 365 });
    cookie.remove(`${CookieStore.prefix}.${key}`);
  }
}

export default CookieStore;
