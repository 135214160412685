/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { Seo_MegaMenu as SeoMegaMenu } from '__generated__/graphql-types';

import { isUserRightToLeft } from 'js/lib/language';

import { LoadingSection } from '@coursera/cds-core';
import { track } from '@coursera/event-pulse/sdk';

import { getRootItem } from 'bundles/browse/components/PageHeader/constants';
import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import type { ProfessionalCertificateProps } from 'bundles/browse/components/types/ProfessionalCertificate';
import type { DegreeProductMember } from 'bundles/browse/types/degree-list';
import MegaMenuMain from 'bundles/megamenu/components/MegaMenuMain';
import MegaMenuSubPanel from 'bundles/megamenu/components/MegaMenuSubPanel';
import styles from 'bundles/megamenu/components/__styles__/MegaMenuContent';
import { DEFAULT_MENU_ORIGIN, domainIdOrderFromKittHeuristic } from 'bundles/megamenu/constants/constants';
import type { MegaMenuData, MenuItem, mappedMegaMenuAPIData } from 'bundles/megamenu/types/MenuData';
import { getMegaMenuSectionData } from 'bundles/megamenu/utils/MegaMenuUtils';

export type Props = {
  menuIsOpen: boolean;
  isUsingKeyboard?: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  anchorElement?: HTMLElement | null;
  renderContentsOnly?: boolean; // If you'd like your own parent container, for lazy loading this component
  showLoadingState?: boolean; // Show a loading icon when content is still fetching data
  domains: Domain[];
  degreeListFromPremiumProductsCollection?: DegreeProductMember[];
  mastertrackListFromPremiumProductsCollection?: DegreeProductMember[];
  professionalCertificates: ProfessionalCertificateProps[];
  megaMenuAPIData?: mappedMegaMenuAPIData;
  premiumProductsCollectionLoading?: boolean;
  professionalCertificatesLoading?: boolean;
  megaMenuSeoData?: SeoMegaMenu;
};

export type State = {
  selectedDomain?: MenuItem;
  megaMenuData?: MegaMenuData[];
  megaMenuIsLoaded?: boolean;
  isUsingArrowKeys: boolean;
};

type CursorPosition = {
  x: number;
  y: number;
};

const mouseEventToCoord = (mouseEvent: React.MouseEvent) => ({ x: mouseEvent.clientX, y: mouseEvent.clientY });
const coordsAreEqual = (a: CursorPosition, b: CursorPosition) => a.x === b.x && a.y === b.y;

const rootDomain = getRootItem();

export class MegaMenuContent extends React.Component<Props, State> {
  subMenuNode: HTMLElement | undefined | null;

  cursorDomain: MenuItem | undefined;

  previousCursorPosition: CursorPosition | undefined;

  currentCursorPosition: CursorPosition | undefined;

  state: State = {
    selectedDomain: rootDomain,
    megaMenuIsLoaded: false,
    isUsingArrowKeys: false,
  };

  constructor(props: Props) {
    super(props);
    this.initCursorPosition();
    this.setCursorDomain(rootDomain);
  }

  componentDidMount() {
    this.addFocusToMenu();
  }

  // @TODO Migrate to getDerivedStateFromProps
  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.menuIsOpen) {
      this.clearCursorPositionDomain();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { megaMenuData, megaMenuIsLoaded } = this.state;

    if (!megaMenuIsLoaded) {
      const {
        anchorElement,
        domains,
        degreeListFromPremiumProductsCollection: degreeList = [],
        mastertrackListFromPremiumProductsCollection: mastertrackList = [],
        professionalCertificates,
        megaMenuAPIData,
        premiumProductsCollectionLoading,
        professionalCertificatesLoading,
        megaMenuSeoData,
      } = this.props;
      if (!prevProps.anchorElement && anchorElement) {
        // Menu has rendered for the first time.
        // Set cursor position to initial values so that slope calculations won't generate NaN / Infinity.
        this.initCursorPosition();
      }

      const isLoading = !domains?.length || !megaMenuAPIData;

      const isLoadingRecs = premiumProductsCollectionLoading || professionalCertificatesLoading;

      if (!isLoading && !isLoadingRecs) {
        this.setState(() => ({
          megaMenuData: getMegaMenuSectionData({
            domains,
            degreeList: degreeList?.length ? degreeList : [],
            mastertrackList: mastertrackList?.length ? mastertrackList : [],
            professionalCertificates: professionalCertificates?.length ? professionalCertificates : [],
            megaMenuAPIData,
            megaMenuSeoData,
          }),
          megaMenuIsLoaded: true,
        }));
        // Render the mega menu once the domains and megaMenuAPIData are defined even if the rec service is not fully loaded
      } else if (!megaMenuData && !isLoading && isLoadingRecs) {
        this.setState(() => ({
          megaMenuData: getMegaMenuSectionData({
            domains,
            degreeList: degreeList?.length ? degreeList : [],
            mastertrackList: mastertrackList?.length ? mastertrackList : [],
            professionalCertificates: professionalCertificates?.length ? professionalCertificates : [],
            megaMenuAPIData,
            megaMenuSeoData,
          }),
        }));
      }
    }
  }

  setIsUsingArrowKeys = () => {
    if (this.state.isUsingArrowKeys !== true) {
      this.setState((prevState) => {
        return { ...prevState, isUsingArrowKeys: true };
      });
    }
  };

  handleMouseMove = (evt: React.MouseEvent<HTMLElement>) => {
    const newPosition = mouseEventToCoord(evt);
    if (this.hasCursorJustEnteredMenu()) {
      this.initCursorPosition(newPosition);
      return;
    }

    if (this.cursorDomain) {
      const { selectedDomain } = this.state;
      if (this.compareCursorPosition(newPosition)) return;
      if (this.cursorDomain !== selectedDomain) {
        if (!this.isCursorIsInTrianglePath()) {
          this.setDomain(this.cursorDomain);
        }
      } else if (this.currentCursorPosition) {
        this.previousCursorPosition = this.currentCursorPosition;
      }
      this.currentCursorPosition = newPosition;
    }
  };

  handleMegaMenuClose = (event: React.FocusEvent<HTMLElement>) => {
    const { closeMenu } = this.props;
    if (!this.focusInMegaMenu(event)) {
      closeMenu();
    }
  };

  onKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
    switch (evt.key) {
      case 'Escape':
        evt.preventDefault();
        // TODO: replace with more sane/menu-depth-aware approach
        this.closeCurrentSubmenuOrMenu();
        break;
      default:
    }
  };

  getMenuPosition = (): ClientRect | undefined => {
    const { anchorElement } = this.props;
    if (anchorElement) {
      return anchorElement.getBoundingClientRect();
    }
    return undefined;
  };

  getMenuOrigin = () => {
    const menuPosition = this.getMenuPosition();
    if (!menuPosition) {
      return DEFAULT_MENU_ORIGIN;
    } else if (isUserRightToLeft()) {
      return { x: menuPosition.left + menuPosition.width, y: menuPosition.top };
    } else {
      return { x: DEFAULT_MENU_ORIGIN.x, y: menuPosition.top };
    }
  };

  addFocusToMenu = () => {
    if (this.subMenuNode) this.subMenuNode.focus();
  };

  hasCursorJustEnteredMenu = () => {
    const { previousCursorPosition } = this;
    return coordsAreEqual(previousCursorPosition || DEFAULT_MENU_ORIGIN, DEFAULT_MENU_ORIGIN);
  };

  focusInMegaMenu = (event: React.FocusEvent<EventTarget>) => {
    const { relatedTarget, currentTarget } = event;
    if (relatedTarget === null) {
      return false;
    }

    let node: Node | null = null;
    if (relatedTarget instanceof HTMLElement) {
      node = relatedTarget.parentNode;
    }

    while (node !== null) {
      if (node === currentTarget) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  };

  /*
    NOTE: This is largely responsible for mantaining sub-domain focuse while scrolling from
          the main portion of megamenu to it's subdomain content. What it does is this:

          By referencing the top and bottom of the displayed subdomain content, the function
          creates a triangle shaped path through which a mouse can move without updating menu
          selection via hover. This makes it possible to move directly to content in the sub-
          domain menu without accidentally selecting another subdomain in the process. This
          follows a relatively well established menu/hover strategy. Please see the following
          link for more context: https://css-tricks.com/dropdown-menus-with-more-forgiving-mouse-movement-paths/#article-header-id-4
  */
  isCursorIsInTrianglePath = (): boolean => {
    const isRTL = isUserRightToLeft();
    const { subMenuNode, previousCursorPosition, currentCursorPosition } = this;
    const { selectedDomain } = this.state;

    if (subMenuNode && previousCursorPosition && currentCursorPosition) {
      const { x: px, y: py } = previousCursorPosition;
      const { x: cx, y: cy } = currentCursorPosition;

      const didMoveBackwards = isRTL ? cx > px : cx < px;
      if (didMoveBackwards) {
        return false;
      }

      const { top, bottom, left, width } = subMenuNode.getBoundingClientRect();

      /*
        NOTE: A "slopeModifier" is being used as a result of a series of user complaints.
              Specifically, they were complaining that selecting subdomains via hover from
              the megamenu was broken. See the Jira here: https://coursera.atlassian.net/browse/GR-20190

              The slopeModifier's value is entirely arbitrary in the default state, but exists
              exists as a means to limit the slope of the "triangles" described in the note by
              `isCursorIsInTrianglePath`. By making the slope less steep, we aim to make the
              natural movement from the button to the menu content less likely to fall within the
              triangle built to track a path to the default state's submenu content.

              The theory is that when users scrolled from the explore button into its content,
              their mouse movement would naturally fall in line with the calculated triangle path
              built for the default content and that would cause menu selections not to update on hover.
              In the past, the default megamenu state didn't show any promo units so this behavior
              triangle-dependent behavior was never observed.

          ps.: in RTL, the triangle is reflected about the y-axis because their cursor movements are reversed.
        */

      const slopeModifier = selectedDomain === rootDomain ? 0.3333 : 1;

      const edge = isRTL ? left + width : left;

      const upperBoundSlope = (top - py) / (isRTL ? px - edge : edge - px);
      const lowerBoundSlope = Math.max(0, bottom - py) / (isRTL ? px - edge : edge - px);

      const mouseMoveSlope = (cy - py) / (isRTL ? px - cx : cx - px);

      return mouseMoveSlope <= lowerBoundSlope * slopeModifier && mouseMoveSlope >= upperBoundSlope * slopeModifier;
    }

    return false;
  };

  clearCursorPositionDomain = () => {
    this.setRootElement();
    this.initCursorPosition();
  };

  initCursorPosition = (location: CursorPosition = this.getMenuOrigin()) => {
    this.previousCursorPosition = location;
    this.currentCursorPosition = {
      x: this.previousCursorPosition.x + 1,
      y: this.previousCursorPosition.y + 1,
    };
  };

  compareCursorPosition = (newPosition: { x: number; y: number }): boolean => {
    return (
      !!this.currentCursorPosition &&
      newPosition.x === this.currentCursorPosition.x &&
      newPosition.y === this.currentCursorPosition.y
    );
  };

  closeCurrentSubmenuOrMenu = () => {
    const { closeMenu } = this.props;
    const { selectedDomain } = this.state;

    if (selectedDomain === rootDomain) {
      closeMenu();
    } else {
      const domainMenuItem = document.getElementById(`${selectedDomain?.id}~menu-item`);
      domainMenuItem?.focus();
      this.setRootElement();
    }
  };

  setSubMenuWrapper = (node?: HTMLElement) => {
    this.subMenuNode = node;
  };

  setCursorDomain = (domain: MenuItem) => {
    this.cursorDomain = domain;
  };

  setDomain = (selectedDomain?: MenuItem) => {
    this.setState((prevState) => {
      if (prevState.selectedDomain === selectedDomain) {
        return null;
      }

      if (selectedDomain && selectedDomain.menuName !== 'root' && !!selectedDomain.subMenuData) {
        track('view_megamenu_items', {
          megamenuItemName: selectedDomain.id ?? '',
          megamenuSection: domainIdOrderFromKittHeuristic.includes(selectedDomain.id || '') ? 'subjects' : 'goals',
        });
      }

      return { selectedDomain };
    });
  };

  setRootElement = () => {
    this.setCursorDomain(rootDomain);
    this.setDomain(rootDomain);
  };

  renderContents = () => {
    const { closeMenu, showLoadingState, menuIsOpen, isUsingKeyboard } = this.props;
    const { selectedDomain, megaMenuData, isUsingArrowKeys } = this.state;
    const isKeyboardInUse = isUsingKeyboard || isUsingArrowKeys;

    if (!megaMenuData?.length) {
      if (showLoadingState) {
        return (
          <div css={styles.loadingContainer}>
            <LoadingSection hideOverlay css={styles.loadingIcon} />
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <div className="rc-MegaMenuContent" data-e2e="megamenu-content">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div
          css={styles.megaMenu}
          onMouseMove={this.handleMouseMove}
          onKeyDown={this.onKeyDown}
          onBlur={this.handleMegaMenuClose}
          role="group"
          onMouseLeave={this.clearCursorPositionDomain}
        >
          <MegaMenuMain
            closeMenu={closeMenu}
            setCursorDomain={this.setCursorDomain}
            selectedDomain={selectedDomain}
            handleMenuItemSelection={this.setDomain}
            megaMenuData={megaMenuData}
            menuIsOpen={menuIsOpen}
            isUsingKeyboard={isKeyboardInUse}
            setIsUsingArrowKeys={this.setIsUsingArrowKeys}
          />
          <MegaMenuSubPanel
            closeMenu={closeMenu}
            closeSubMenu={this.closeCurrentSubmenuOrMenu}
            selectedDomain={selectedDomain}
            setSubMenuWrapper={this.setSubMenuWrapper}
            megaMenuData={megaMenuData}
            isUsingKeyboard={isKeyboardInUse}
          />
        </div>
      </div>
    );
  };

  render() {
    const { menuIsOpen, openMenu, closeMenu, renderContentsOnly } = this.props;

    const menuPosition = this.getMenuPosition();
    let containerStyle: React.CSSProperties | undefined;
    if (menuPosition) {
      if (isUserRightToLeft()) {
        containerStyle = { right: DEFAULT_MENU_ORIGIN.x };
      } else {
        containerStyle = { left: DEFAULT_MENU_ORIGIN.x };
      }
    }

    if (renderContentsOnly) {
      return this.renderContents();
    } else {
      return (
        <div css={[styles.megaMenuOverlay, !menuIsOpen && styles.megaMenuOverlayHidden]} aria-hidden={!menuIsOpen}>
          <nav css={styles.megaMenuContainer} style={containerStyle} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
            {this.renderContents()}
          </nav>
        </div>
      );
    }
  }
}

export default MegaMenuContent;
