import logger from 'js/app/loggerSingleton';

import type Envelope from '@coursera/event-pulse/core/envelope';

import BaseTransport from './base';
import type { Options as BaseOptions } from './base';

type TransportConstructor = new (options: Options) => BaseTransport;

type OwnOptions = {
  transports: TransportConstructor[];
};

type Options = BaseOptions & Omit<OwnOptions, keyof BaseOptions>;

/**
 * Transport that uses a list of transports to send events. It will try to send
 * the event using the first transport, if it fails it will try the next one
 * and so on.
 */
class PriorityTransport extends BaseTransport {
  private readonly transports: BaseTransport[] = [];

  constructor(options: Options) {
    super(options);

    this.transports = options.transports.map((Transport) => new Transport(options));
  }

  /**
   * @throws {Error} Always throws an error.
   */
  request(): Promise<Response> {
    throw new Error('[PriorityTransport]: Not implemented');
  }

  /**
   * Send the event using the first transport that does not fail.
   *
   * @override
   * @param envelope
   * @throws {Error} If all transports fail to send the event.
   */
  async send(envelope: Envelope): Promise<void> {
    for (const transport of this.transports) {
      try {
        // We keep the await to know if it failed before returning the promise
        // eslint-disable-next-line no-await-in-loop
        return await transport.send(envelope);
      } catch (error) {
        logger.warn('[PriorityTransport] - Failed to send event', error);
      }
    }

    throw new Error('[PriorityTransport]: All transports failed to send the event');
  }
}

export default PriorityTransport;
