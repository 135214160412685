import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props, TypographyVariant } from './Typography';

export const classes = generateEmotionClasses('Typography', [
  'displayBlock',
  'displayInline',
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
]);

type TypographyColor = NonNullable<Props['color']>;

/**
 * Utility that helps to set typography color in Emotion
 */
export const typographyColor: Record<TypographyColor, { color: string }> = {
  inherit: { color: 'inherit' },
  body: { color: 'var(--cds-color-neutral-primary)' },
  invertBody: { color: 'var(--cds-color-neutral-primary-invert)' },
  supportText: { color: 'var(--cds-color-neutral-primary-weak)' },
  primaryHeadline: { color: 'var(--cds-color-callouts-secondary)' },
  error: { color: 'var(--cds-color-feedback-error)' },
  success: { color: 'var(--cds-color-feedback-success)' },
  highlightBlue: { color: 'var(--cds-color-callouts-tertiary)' },
  highlightPurple: { color: 'var(--cds-color-callouts-secondary)' },
  eyebrowYellow: { color: 'var(--cds-color-callouts-secondary-invert)' },
  eyebrowAqua: { color: 'var(--cds-color-callouts-tertiary-invert)' },
  eyebrowPurple: { color: 'var(--cds-color-callouts-secondary)' },
};

/**
 * Utility that helps to style typography in Emotion
 */
export const typography: Record<
  TypographyVariant,
  { font: string; letterSpacing: string }
> = {
  inherit: {
    font: 'inherit',
    letterSpacing: 'inherit',
  },
  display: {
    font: 'var(--cds-typography-display)',
    letterSpacing: 'var(--cds-letter-spacing-minus100)',
  },
  titleLarge: {
    font: 'var(--cds-typography-title-large)',
    letterSpacing: 'var(--cds-letter-spacing-minus100)',
  },
  titleMedium: {
    font: 'var(--cds-typography-title-medium)',
    letterSpacing: 'var(--cds-letter-spacing-minus50)',
  },
  titleSmall: {
    font: 'var(--cds-typography-title-small)',
    letterSpacing: 'var(--cds-letter-spacing-minus50)',
  },
  subtitleLarge: {
    font: 'var(--cds-typography-subtitle-large)',
    letterSpacing: 'var(--cds-letter-spacing-minus30)',
  },
  subtitleMedium: {
    font: 'var(--cds-typography-subtitle-medium)',
    letterSpacing: 'var(--cds-letter-spacing-minus30)',
  },
  bodyPrimary: {
    font: 'var(--cds-typography-body-primary)',
    letterSpacing: 'var(--cds-letter-spacing-0)',
  },
  bodySecondary: {
    font: 'var(--cds-typography-body-secondary)',
    letterSpacing: 'var(--cds-letter-spacing-0)',
  },
  actionPrimary: {
    font: 'var(--cds-typography-action-primary)',
    letterSpacing: 'var(--cds-letter-spacing-100)',
  },
  actionSecondary: {
    font: 'var(--cds-typography-action-secondary)',
    letterSpacing: 'var(--cds-letter-spacing-100)',
  },
};

export default css`
  margin: 0;

  &.${classes.displayBlock} {
    display: block;
  }

  &.${classes.displayInline} {
    display: inline;
  }

  &.${classes.alignLeft} {
    text-align: left;
  }

  &.${classes.alignCenter} {
    text-align: center;
  }
  &.${classes.alignRight} {
    text-align: right;
  }

  &.${classes.alignJustify} {
    text-align: justify;
  }

  strong {
    /* stylelint-disable-next-line cds-stylelint/cds-design-tokens */
    font-weight: var(--cds-font-weight-700);
    font-family: inherit;
  }
`;
