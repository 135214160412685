import { memoize } from 'lodash';

import Device from './device';
import { createDefaultStore } from './store';
import type { Store } from './store';

type Options = {
  store: Store;
};

class DeviceProvider {
  private readonly instance: Device;

  private store: Store;

  constructor(options: Options) {
    this.store = options.store;

    // Restore or create a new device
    const previous = this.store.get<Partial<Device>>('device');
    this.instance = new Device({ ...previous });
  }

  get(): Device {
    return this.instance;
  }

  touch(): void {
    // Store the new device
    this.store.set('device', this.instance.toJSON());
  }
}

const createDefaultDeviceProvider = memoize(() => new DeviceProvider({ store: createDefaultStore() }));

export { createDefaultDeviceProvider };
export default DeviceProvider;
