import type { Router } from 'react-router';

import inServerContext from 'bundles/ssr/util/inServerContext';

type GetComponent = (
  nextState: Router.RouterState | Router.LocationDescriptor,
  cb: (error: Error | null, component?: Router.RouteComponent) => void
) => void;

type ImportPromise = () => Promise<{ default: Router.RouteComponent } | Router.RouteComponent>;
type BuildOnRoute = { moduleName: string };

const hasDefaultExport = (
  module: { default: Router.RouteComponent } | Router.RouteComponent
): module is { default: Router.RouteComponent } => {
  return typeof module === 'object' && 'default' in module;
};

const prefersReducedMotion = () => !inServerContext && window.matchMedia('(prefers-reduced-motion)').matches;

const createAsyncHandlerWithPromise =
  (importModulePromiseFactory: ImportPromise, viewTransition?: boolean): GetComponent =>
  // This is the
  // [getComponents](https://github.com/reactjs/react-router/blob/bd9f53f/docs/API.md#getcomponentsnextstate-callback)
  // callback from `react-router`
  (_nextState, cb) => {
    const asyncImport = () =>
      importModulePromiseFactory()
        .then((module) => cb(null, hasDefaultExport(module) ? module.default : module))
        .catch((err) => {
          console.log('Dynamic route loading failed', err); // eslint-disable-line no-console
          cb(err);
        });

    if (inServerContext || !document.startViewTransition || !viewTransition || prefersReducedMotion()) {
      asyncImport();
      return;
    }

    document.startViewTransition(asyncImport);
  };

type Options = {
  viewTransition?: boolean;
};

const isImportPromise = (mod: ImportPromise | BuildOnRoute): mod is ImportPromise => {
  return typeof mod === 'function' && mod.length === 0;
};

const loadOnRoute = (module: ImportPromise | BuildOnRoute, { viewTransition }: Options = {}): GetComponent => {
  if (!isImportPromise(module)) {
    throw new Error(
      'Should not pass in a raw import(...) statement to `loadOnRoute` as ' +
        'it will not lazily load the code! ' +
        'Please pass in a promise factory like `() => import(...) instead.'
    );
  }

  return createAsyncHandlerWithPromise(module, viewTransition);
};

export const loadOnRouteWithTransition = (module: ImportPromise | BuildOnRoute): GetComponent => {
  return loadOnRoute(module, { viewTransition: true });
};

export default loadOnRoute;
