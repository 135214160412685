import type React from 'react';

import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

import { css, Global } from '@emotion/react';

import { ClientSideEmotionCacheProvider } from '@common/components/EmotionCacheProvider';
import EmotionThemeProvider from '@common/theme/EmotionThemeProvider';
import type { Theme } from '@common/types';
import deprecatedColors from '@coursera/cds-design-tokens/build/css/default/deprecated-colors.css';
import deprecatedTypographyRtlStyles from '@coursera/cds-design-tokens/build/css/default/deprecated-typography-rtl.css';
import deprecatedTypographyStyles from '@coursera/cds-design-tokens/build/css/default/deprecated-typography.css';
import globalStyles from '@coursera/cds-design-tokens/build/css/default/global.css';
import rtlStyles from '@coursera/cds-design-tokens/build/css/default/typography-rtl.css';

import StylesProvider from './StylesProvider';

type Props = {
  theme: Theme;
};

const ThemeProvider: React.FC<Props> = ({ children, theme }) => {
  return (
    <ClientSideEmotionCacheProvider direction={theme.direction}>
      <MUIThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          {/** Required wrapper as work-around this issue: https://github.com/enzymejs/enzyme/issues/2549. Do not remove. */}
          <>
            <Global
              styles={css`
                ${globalStyles}
                ${rtlStyles}
                ${deprecatedTypographyStyles}
                ${deprecatedTypographyRtlStyles}
                ${deprecatedColors}
              `}
            />
            <StylesProvider>{children}</StylesProvider>
          </>
        </EmotionThemeProvider>
      </MUIThemeProvider>
    </ClientSideEmotionCacheProvider>
  );
};

export default ThemeProvider;
