import { RestLink } from 'apollo-link-rest';
import camelCase from 'lodash/camelCase';
import nodeFetch, { Headers } from 'node-fetch';

import isSSR from './lib/isSSR';

// apollo-link-rest requires Headers to be global:
// https://github.com/apollographql/apollo-link-rest/issues/181
// We do have polyfill for browsers, but for ssr and for tests we still need to manually
// add Headers to global scope.
let customFetch;
if (isSSR()) {
  global.Headers = Headers;
  customFetch = nodeFetch;
}

// Add camelCase fieldNameNormalizer by default. This is needed for fields like `courses.v1`
// because GraphQL does not allow dots in field names.
class NaptimeRestLink extends RestLink {
  constructor(options) {
    super({
      ...options,
      customFetch: options.customFetch || customFetch,
      fieldNameNormalizer: options.fieldNameNormalizer || ((key) => camelCase(key)),
    });
  }
}

export default NaptimeRestLink;
