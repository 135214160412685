import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { getPaletteColor } from './colors';
import type { DialogColor, DialogVariant } from './types';

const variants = {
  standard: css`
    padding: 0 var(--cds-spacing-600) var(--cds-spacing-400)
      var(--cds-spacing-600);

    ${breakpoints.down('xs')} {
      padding: 0 var(--cds-spacing-200) var(--cds-spacing-300)
        var(--cds-spacing-200);
    }
  `,

  transactional: css`
    padding: var(--cds-spacing-600) var(--cds-spacing-600)
      var(--cds-spacing-400) var(--cds-spacing-600);

    ${breakpoints.down('xs')} {
      padding: var(--cds-spacing-200) var(--cds-spacing-200)
        var(--cds-spacing-300) var(--cds-spacing-200);
    }
  `,
};

export const getHeadingGroupCss = ({
  color,
  variant,
}: {
  color: DialogColor;
  variant: DialogVariant;
}) => {
  return css`
    background-color: ${getPaletteColor(color)};

    ${variants[variant]}
  `;
};
