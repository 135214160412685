/**
 * Javascript client library for EPIC, the
 *
 * the Experimentation Platform and Instrumentation for Coursera.
 *
 * Documentation at https://docs.dkandu.me/projects/epic.html
 *
 * Only the parameters defined in epic_site.json can be overridden.
 * Please define the parameters in the epic_site if the parameter you
 * want to experiment is not defined yet.
 *
 * Usage:
 *   import epicClient from 'bundles/epic/client';
 *   const value = epicClient.get("replace by namespace", "replace by parameter name");
 *
 * WARNING: The get function may send an impression to eventing depending on
 * whether the parameter is overridden or not. So, please make sure
 * that the returned variable is used in the following code,
 * otherwise, you might have corrupted data for your experiments.
 */
import _ from 'lodash';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import Defaults from 'bundles/epic/lib/Defaults';
import type { DefaultNamespaces, NamespacedDefaults, Override, Tags, Value } from 'bundles/epic/lib/EpicTypes';

const COPY_TEST_EPIC_NAMESPACE = 'CopyTest';

function parameterFullname(namespaceName: string, parameterName: string) {
  return namespaceName + ':' + parameterName;
}

// TODO(ppaskaris): Replace this with namespaceDefaults param
function isNamespaceWithDynamicValueNames(namespaceName: string) {
  return namespaceName === COPY_TEST_EPIC_NAMESPACE;
}

const DevOverridesNotValid = Symbol('DevOverridesNotValid');

type RecordingFunctionErrorValue = {
  message: string;
  parameterOfMissingParameter: string;
  namespaceOfMissingParameter: string;
};
type RecordingFunctionValue = [key: string, value: RecordingFunctionErrorValue | Override];
type RecordingFunction = (value: RecordingFunctionValue) => void;
type ServerValuesIndexes = { [key: string]: Override };

type Trace = {
  keys: Set<string>;
};

class EpicClient {
  record: RecordingFunction;

  serverValuesIndexes: ServerValuesIndexes;

  _memoizedGetOrPreview: (
    namespaceName: string,
    parameterName: string,
    tags: Tags | undefined,
    isPreview: boolean
  ) => Value;

  /**
   * @return {object} a dict that maps full parameter names (keys) to
   * their default values.
   */
  static buildIndexForDefaultParameters(defaultNamespaces: DefaultNamespaces): NamespacedDefaults {
    const parameterList = _.map(defaultNamespaces, function (defaultNamespace) {
      const namespace = defaultNamespace.name;
      return _.map(defaultNamespace.parameters, function (parameter) {
        return [parameterFullname(namespace, parameter.name), parameter.value];
      });
    });

    return _.fromPairs(
      _.flatten(parameterList) // shallow flatten
    );
  }

  addDefaults(defaultNamespaces: DefaultNamespaces) {
    defaultNamespaces.forEach((ns) => Defaults.add(ns));
  }

  /**
   * Construct an EpicClient instance. There should be as few as necessary.
   * Before writing code that instantiates this, see discussion at
   * https://phabricator.dkandu.me/D16465?id=58443#inline-160285
   *
   * In the non-SSR browser env there is one in the `epic/client` module
   * singleton. In SSR, there is one on the server for each request. After
   * rehydration another is made from the dehydrated state. That one is in some
   * way redundant to the module singleton, but it is needed to live within the
   * dehydrate/rehydrate flow. This isn't a problem as long as the overrides
   * are identical. (The default will be by virtue of the module loader.)
   *
   * @param {Function} eventRecordingFn takes an array of key, value pars. E.g. Multitracker.push
   * @param {Array} serverValuesOrOverrides a list of values, from Naptime `overrideParameters.v1` resource
   *                note: serverOverrides includes actual user bucketing values
   */
  constructor(eventRecordingFn: RecordingFunction, serverValuesOrOverrides: Override[]) {
    this.record = eventRecordingFn;
    this.serverValuesIndexes = this.buildIndexForOverrideParameters(serverValuesOrOverrides);

    // Memoize get so we don't emit multiple tracking events for epic.show
    const memoizeHashFunction = (...args: Value[]) => args.map((arg) => JSON.stringify(arg)).join('.');
    this._memoizedGetOrPreview = _.memoize(this._getOrPreview, memoizeHashFunction);
  }

  /**
   * @return {object} a dict that maps full parameter names (keys) to
   * their overridden parameters (including the values and other
   * logging information such as experiment names, etc.
   */
  buildIndexForOverrideParameters(overrideParameters: Override[]): ServerValuesIndexes {
    return _.fromPairs(
      _.map(overrideParameters, function (overrideParameterWithId) {
        // Remove the id field, since the object will be sent to
        // Eventing, and this field in the object is useless in
        // logging and further analysis.
        // But don't delete it from the argument because it is required by Naptime.
        const overrideParameter = _.omit(overrideParameterWithId, 'id');
        return [parameterFullname(overrideParameter.namespace, overrideParameter.parameterName), overrideParameter];
      })
    );
  }

  /**
   * Returns the value of the parameter in the given namespace, if
   * the parameter is not defined in the epic_site.json file, it
   * will undefined instead.
   * Any provided tags will be recorded as part of the impression,
   * as well as used for determining which tag-targeted experiments
   * to include.
   * @param {String} namespaceName
   * @param {String} parameterName
   * @param {Object} tags A collection of String tags to be recorded, e.g. {course_id: '123'}
   */
  get(namespaceName: string, parameterName: string, tags: Tags | undefined): Value {
    return this._memoizedGetOrPreviewWithLocalOverride(namespaceName, parameterName, tags, false /* isPreview */);
  }

  /**
   * Returns the value of the parameter in the given namespace, if
   * the parameter is not defined in the epic_site.json file, it
   * will undefined instead.
   * No impression will be made.
   * @param {String} namespaceName
   * @param {String} parameterName
   * @param {Object} tags A collection of String tags to be recorded, e.g. {course_id: '123'}
   */
  preview(namespaceName: string, parameterName: string, tags: Tags | undefined): Value {
    return this._memoizedGetOrPreviewWithLocalOverride(namespaceName, parameterName, tags, true /* isPreview */);
  }

  _memoizedGetOrPreviewWithLocalOverride(
    namespaceName: string,
    parameterName: string,
    tags: Tags | undefined,
    isPreview: boolean
  ): Value {
    // for debugging
    // TODO refactor this to not bring localStorage into clientFactory
    const devOverrides = localStorageEx.getItem(
      'EpicOverrides',
      JSON.parse,
      /* valueIfNotFound: */ undefined,
      /* valueIfNotAvailable: */ undefined,
      /* valueIfNotDeserialized: */ DevOverridesNotValid
    );
    if (devOverrides === DevOverridesNotValid) {
      // eslint-disable-next-line no-console
      console.error(
        'Your override string must be JSON in the form %c{"namespace":{"variable":<value>}}',
        'font-family:monospace;color:#111;background-color:#eee;'
      );
    } else {
      const devOverride = devOverrides?.[namespaceName]?.[parameterName];
      if (devOverride !== undefined) {
        return devOverride;
      }
    }

    return this._memoizedGetOrPreview(namespaceName, parameterName, tags, isPreview);
  }

  /**
   * @param {String} namespaceName
   * @param {String} parameterName
   * @param {Object} tags A collection of String tags to be recorded, e.g. {course_id: '123'}
   * @param {boolean} isPreview If just previewing, don't make an impression.
   */
  _getOrPreview(namespaceName: string, parameterName: string, tags: Tags | undefined, isPreview: boolean): Value {
    const fullname = parameterFullname(namespaceName, parameterName);
    const defaultValue: Value = Defaults.get(namespaceName, parameterName);

    // This serverValue is user bucketed value or overridden value from server
    let serverValue = this.serverValuesIndexes[fullname];

    if (serverValue !== undefined) {
      // We only override if there is a default value for the same parameter
      if (defaultValue !== undefined || isNamespaceWithDynamicValueNames(namespaceName)) {
        // If you want to log extra information, please consider
        // changing the overrideParameter case class in the EPIC
        // service code or changing multitracker.
        if (tags && _.isObject(tags)) {
          // Append tags to the serverValue we record as part of the impression
          // but don't touch the copy in the store, in case it's accessed again later
          // with different (or no) tags.
          serverValue = _.clone(serverValue);
          serverValue.tags = tags;
        }

        if (serverValue.tagTarget) {
          // tag targeting enabled
          const target = serverValue.tagTarget;

          if (!(tags && _.isObject(tags) && tags[target.tagName])) {
            // tag not set
            const msg = 'the experiment on ' + fullname + ' expects a ' + target.tagName + ' tag';
            console.error(msg); // eslint-disable-line no-console
            return defaultValue;
          }

          if (target.targetType === 'BLACKLIST') {
            if (_.includes(target.tagValues, tags[target.tagName])) return defaultValue;
          } else if (target.targetType === 'WHITELIST') {
            if (!_.includes(target.tagValues, tags[target.tagName])) return defaultValue;
          }

          // At this point we have a tag match, and we know the serverValue object
          // was cloned, so it's safe to modify it.
          // We want to strip tagTarget out because it can make the event too big.
          delete serverValue.tagTarget;
        }

        if (!isPreview) {
          this.record(['epic.experiment.show', serverValue]);
        }
        // TODO(zhaojun): placeholder for sending events back in the
        // format of the existing ab test framework. then, we can check
        // EPIC with the existing dashboards.
        return serverValue.value;
      } else {
        const message = 'Attempting to override a parameter that does not exist in the default file: ' + fullname;
        this.record([
          'epic.client.js.error',
          {
            message,
            parameterOfMissingParameter: parameterName,
            namespaceOfMissingParameter: namespaceName,
          },
        ]);
      }
    }

    return defaultValue;
  }
}

export default EpicClient;
