import * as React from 'react';
import { Query } from 'react-apollo';

import user from 'js/lib/user';

import SeoPathConfigurationQuery from 'bundles/seo/api/SeoPathConfigurationQuery.graphql';
import type { SeoPathConfigurationQueryQuery as Response } from 'bundles/seo/api/__generated__/SeoPathConfigurationQuery';
import { SeoPageDataContextProvider } from 'bundles/seo/contexts/SeoPageDataContext';

type Props = {
  path: string;
  shouldSkip?: boolean;
  children: React.ReactElement | null;
};

const SeoPathConfigurationQueryWrapper: React.FC<Props> = ({ path, children, shouldSkip = false }) => {
  return (
    <Query<Response>
      query={SeoPathConfigurationQuery}
      variables={{ path }}
      context={{ clientName: 'gatewayGql' }}
      skip={user.isAuthenticatedUser() || shouldSkip}
      ssr={true}
      errorPolicy="all"
    >
      {(queryResult) => {
        const seoPageData = queryResult?.data?.Seo?.seoPathConfigurationQuery.pageData;
        const eqpData = queryResult?.data?.Seo?.seoPathConfigurationQuery.eqpData;
        const subfooter = seoPageData?.subfooter?.categories;
        const globalFooterLinksForCategory = seoPageData?.globalFooter?.categories?.reduce(
          (accumulator, currentCategory) => {
            return { ...accumulator, [currentCategory.header]: currentCategory.links };
          },
          {}
        );
        const metaTags = seoPageData?.metaTags ?? undefined;
        const megaMenu = seoPageData?.megaMenu ?? undefined;

        return (
          <SeoPageDataContextProvider
            subfooter={subfooter}
            globalFooterLinksForCategory={globalFooterLinksForCategory}
            metaTags={metaTags}
            megaMenu={megaMenu}
            eqpData={eqpData}
          >
            {children}
          </SeoPageDataContextProvider>
        );
      }}
    </Query>
  );
};

export default SeoPathConfigurationQueryWrapper;
