import BaseTransport from './base';

/**
 * Transport that uses a script tag to send events. It does not support batching.
 *
 * An script tag is created and added to the head of the document. The script
 * tag will have the event data as query parameters in the URL.
 */
class ScriptTransport extends BaseTransport {
  /**
   * Create a script tag with the given data encoded as query parameters.
   *
   * @param data
   */
  request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    return new Promise((ok) => {
      const script = document.createElement('script');

      script.async = true;
      script.onerror = () => {
        ok(new Response(undefined, { status: 500 }));
      };
      script.onload = () => {
        ok(new Response(undefined, { status: 200 }));
      };
      script.type = 'text/javascript';

      // Set the script source
      const url = new URL(this.url, window.location.origin);
      Object.entries(data).forEach(([key, value]) =>
        url.searchParams.append(key, typeof value === 'object' ? JSON.stringify(value) : String(value))
      );

      script.src = url.toString();

      // Set the script inside the head
      document.head.appendChild(script);
    });
  }
}

export default ScriptTransport;
