import logger from 'js/app/loggerSingleton';

import BaseTransport from '@coursera/event-pulse/core/transport/base';

/**
 * Transport that uses the client console log to record the event.
 *
 * It offers a simple way to debug the events that are being sent.
 */
class LoggerTransport extends BaseTransport {
  /**
   * Uses the client console log to record the event.
   *
   * @param data
   */
  async request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    logger.info('[Eventing] - send:', data);

    return new Response(undefined, { status: 200 });
  }
}

export default LoggerTransport;
