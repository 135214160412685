/* eslint-disable camelcase */
import type { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { merge, omitBy } from 'lodash';

import * as user from 'js/lib/user';

import type { Events } from '@coursera/event-pulse/core';

import type { checkSwitcherProgram, checkSwitcherProgramVariables } from './__generated__/checkSwitcherProgram';
import { getCampaignParameters } from './campaign';
import { high as client } from './client';
import mutex from './mutex';
import * as memory from './pageMemory';

type Options = {
  defaultSegment?: PageViewOptions['page']['segment'];
  graphqlClient?: ApolloClient<{}>;
  segmentOptIn?: boolean;
};

type PageViewOptions = Omit<Events['view_page'], 'user_properties'>;

async function isEnterprisePage({ graphqlClient }: { graphqlClient: ApolloClient<{}> }): Promise<boolean> {
  const response = await graphqlClient.query<checkSwitcherProgram, checkSwitcherProgramVariables>({
    query: gql`
      query checkSwitcherProgram($userId: String!) {
        ProgramSwitcherSelectionsV1Resource {
          get(id: $userId) {
            id
            selectionType
            programId
            degreeId
          }
        }
      }
    `,
    variables: {
      userId: String(user.get().id),
    },
  });

  return !!response.data.ProgramSwitcherSelectionsV1Resource.get?.programId;
}

// This function tracks page views, it is called in the main router file.
// Developers should not need to call this function directly.
/**
 * Track a page view.
 *
 * DO NOT USE IT DIRECTLY.
 * It is called automatically when the router changes the page.
 *
 * @param options
 * @param defaultSegment
 * @param graphqlClient
 * @param segmentOptIn
 */
async function trackPageView(
  options: Partial<PageViewOptions> = {},
  { defaultSegment, graphqlClient, segmentOptIn }: Options = {}
): Promise<void> {
  await mutex(async () => {
    let segment = options.page?.segment;

    // await for segment to be ready
    if (!segment && segmentOptIn) {
      if (user.isAuthenticatedUser() && graphqlClient) {
        segment = (await isEnterprisePage({ graphqlClient })) ? 'enterprise' : 'consumer';
      } else {
        // I'm almost sure this should throw an error, but I'm not sure what the error should be
        segment = defaultSegment;
      }
    }

    const campaign = getCampaignParameters();

    const payload = merge<{}, Partial<PageViewOptions>, PageViewOptions>({}, options, {
      page: omitBy(
        {
          appName: window.appName,
          baseUrl: window.location.href.split('?')[0],
          query: window.location.search,
          referrerBaseUrl: document.referrer.split('?')[0],
          referrerUrl: document.referrer,
          segment,
          semCampaignId: campaign.campaignid,
          semCreativeId: campaign.creativeid,
          semKeyword: campaign.keyword,
          url: window.location.href,
          utmCampaign: campaign.utm_campaign,
          utmContent: campaign.utm_content,
          utmMedium: campaign.utm_medium,
          utmSource: campaign.utm_source,
          utmTerm: campaign.utm_term,
        },
        // We clean up the payload to remove falsey values
        (value) => !value
      ),
    });

    memory.set(payload.page);

    return client.sendEvent('view_page', { ...payload, user_properties: {} });
  });
}

export type { PageViewOptions as Options };
export default trackPageView;
