import * as Sentry from '@sentry/react';
import Uri from 'jsuri';
import urlJoin from 'url-join';

import config from 'js/app/config';
import logger from 'js/app/loggerSingleton';
import redirect from 'js/lib/coursera.redirect';
import * as user from 'js/lib/user';

import { supportedSubfolderLocaleCodes } from 'bundles/internationalization-lib/constants/subfolderLocaleCodes';
import { urlPathLocaleSubfolderPatternMatcher } from 'bundles/internationalization-lib/utils/subfolderPatternMatcher';
import { RedirectError } from 'bundles/ssr/lib/errors/directives';
import inServerContext from 'bundles/ssr/util/inServerContext';

const domain = new Uri(config.url.base).host().split('.').slice(1).join('.');

const hubPageRelativeUrls = {
  // This is an incomplete list. Add additional hub pages when needed.
  degreesHub: '/degrees',
} as const;

export const urlConstants = {
  absoluteDomainRootWithProcotocol: config.url.base,
  domain,
  degreeUrlRoot: 'degrees',
  degreeDescriptionUrlRoot: 'degree-description',
  degreesHubRelativePath: '/degrees',
  certificateUrlRoot: 'professional-certificates',
  certificatesUrlRoot: 'certificates',
  courseRoot: 'learn',
  projectRoot: 'projects',
  mastertrackRoot: 'mastertrack',
  s12nsUrlRoot: 'specializations',
  protocol: 'https',
  programRoot: 'teach-program',
  learnerHelpCenterHome: 'https://learner.coursera.help',
  partnerHelpCenterHome: 'https://partner.coursera.help',
  hubPageRelativeUrls,
} as const;

// Matches the coursera.org domain explicitly, not when it's in other subdomains.
// https://regex101.com/r/b33xtm/1
const domainRegex = /coursera\.org$/;

// Matches a link like www.google.com but not https://google.com
// https://regex101.com/r/kpTZqe/1
const linkWithoutProtocol = /^[A-Za-z0-9]*\.[A-Za-z0-9]*(\.[A-Za-z0-9]*)*/;

// Only matches links with a double slash in the beginning, like //google.com
// https://regex101.com/r/zNmLZc/1
const linkWithDoubleSlash = /^\/\/[A-Za-z0-9]*\.[A-Za-z0-9]*(\.[A-Za-z0-9]*)*/;

const catchError = (msg: string, error: unknown) => {
  logger.warn(msg, error);
  return null;
};

export const { degreeUrlRoot, certificateUrlRoot, protocol } = urlConstants;

export const getLectureVideoUrl = (courseSlug: string, lectureItemId: string, lectureItemSlug: string): string =>
  urlJoin('/lecture', courseSlug, `${lectureItemSlug}-${lectureItemId}`);

type UrlBuilderFunc = (slug: string, path?: string, prefixPath?: string) => string;

/**
 * Builds an absolute URL.
 * like https://www.coursera.org/
 */
export const buildCanonicalUrl: UrlBuilderFunc = (path = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, path);
};

/**
 * Builds a URL like /professional-certificate/google-it-support or
 * /professional-certificate/google-it-support/your/extra/path or
 * /prefixPath/professional-certificate/google-it-support/your/extra/path or
 * /prefixPath/professional-certificate/google-it-support
 */
export const buildProfessionalCertificateUrlRelative: UrlBuilderFunc = (slug, path = '', prefixPath = '') =>
  urlJoin('/', prefixPath, urlConstants.certificateUrlRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/professional-certificate/google-it-support or
 * https://www.coursera.org/professional-certificate/google-it-support/your/extra/path or
 * https://www.coursera.org/prefixPath/professional-certificate/google-it-support/your/extra/path
 */
export const buildProfessionalCertificateUrlAbsolute: UrlBuilderFunc = (slug, path = '', prefixPath = '') =>
  urlJoin(
    urlConstants.absoluteDomainRootWithProcotocol,
    buildProfessionalCertificateUrlRelative(slug, path, prefixPath)
  );

/**
 * Builds a URL like /degrees/imba or /degrees/imba/your/extra/path
 */
export const buildDegreeUrlRelative: UrlBuilderFunc = (slug, path = '') =>
  urlJoin('/', urlConstants.degreeUrlRoot, slug, path);

/**
 * Builds a URL like /learn/trees-graphs-basics or
 * /learn/trees-graphs-basics/your/extra/path or
 * /prefixPath/learn/trees-graphs-basics/your/extra/path or
 * /prefixPath/learn/trees-graphs-basics
 */
export const buildCourseUrlRelative: UrlBuilderFunc = (slug, path = '', prefixPath = '') =>
  urlJoin('/', prefixPath, urlConstants.courseRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/learn/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/learn/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/prefixPath/learn/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/prefixPath/learn/trees-graphs-basics
 */
export const buildCourseUrlAbsolute: UrlBuilderFunc = (slug, path = '', prefixPath = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildCourseUrlRelative(slug, path, prefixPath));
};

/**
 * Builds a URL like /projects/trees-graphs-basics or
 * /projects/trees-graphs-basics/your/extra/path or
 * /prefixPath/projects/trees-graphs-basics/your/extra/path or
 * /prefixPath/projects/trees-graphs-basics
 */
export const buildProjectUrlRelative: UrlBuilderFunc = (slug, path = '', prefixPath = '') =>
  urlJoin('/', prefixPath, urlConstants.projectRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/projects/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/projects/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/prefixPath/projects/trees-graphs-basics/your/extra/path or
 * https://www.coursera.org/prefixPath/projects/trees-graphs-basics
 */
export const buildProjectUrlAbsolute: UrlBuilderFunc = (slug, path = '', prefixPath = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildProjectUrlRelative(slug, path, prefixPath));
};

/**
 * Builds a URL like /degree-description/imba or /degrees/imba/your/extra/path
 */
export const buildDegreeDescriptionUrlRelative: UrlBuilderFunc = (slug, path = '') =>
  urlJoin('/', urlConstants.degreeDescriptionUrlRoot, slug, path);

/**
 * Builds a URL like /degrees/business or /degrees/business/your/extra/path
 */
export const buildDegreeCategoryUrlRelative: UrlBuilderFunc = (category, path = '') =>
  urlJoin('/', urlConstants.degreeUrlRoot, category, path);

/**
 * Builds a URL like https://www.coursera.org/degrees/imba or https://www.coursera.org/degrees/imba/your/extra/path
 */
export const buildDegreeUrlAbsolute: UrlBuilderFunc = (slug, path = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildDegreeUrlRelative(slug, path));
};

/**
 * Builds a URL like /certificates/launch-a-program
 */
export const buildCertificatesUrlRelative: UrlBuilderFunc = (slug, path = '') =>
  urlJoin('/', urlConstants.certificatesUrlRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/certificates/launch-a-program or https://www.coursera.org/degrees/imba/your/extra/path
 */
export const buildCertificatesUrlAbsolute: UrlBuilderFunc = (slug, path = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildCertificatesUrlRelative(slug, path));
};

/**
 * Builds a URL like /specializations/launch-a-program or
 * /prefixPath/specializations/launch-a-program or
 * /prefixPath/specializations/launch-a-program/your/extra/path
 */
export const buildS12nsUrlRelative: UrlBuilderFunc = (slug, path = '', prefixPath = '') =>
  urlJoin('/', prefixPath, urlConstants.s12nsUrlRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/specializations/mathematics-for-data-science or
 * https://www.coursera.org/specializations/mathematics-for-data-science/your/extra/path or
 * https://www.coursera.org/prefixPath/specializations/mathematics-for-data-science/your/extra/path or
 * https://www.coursera.org/prefixPath/specializations/mathematics-for-data-science
 */
export const buildS12nsUrlAbsolute: UrlBuilderFunc = (slug, path = '', prefixPath = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildS12nsUrlRelative(slug, path, prefixPath));
};

/**
 * Builds a URL for using on external sharing services like email, gplus, facebook, etc.
 * like coursera.org/degrees/imba
 */
export const buildDegreeShareLink: UrlBuilderFunc = (slug, path = '') => {
  return urlJoin(urlConstants.domain, buildDegreeUrlRelative(slug, path));
};

export const buildMastertrackUrlRelative: UrlBuilderFunc = (slug, path = '') =>
  urlJoin('/', urlConstants.mastertrackRoot, slug, path);

/**
 * Builds a URL like https://www.coursera.org/mastertrack/
 */
export const buildMastertrackUrlAbsolute: UrlBuilderFunc = (slug, path = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, buildMastertrackUrlRelative(slug, path));
};

/**
 *
 * Builds an url like
 * @returns
 */

export const prependUrlProtocolIfNone: (x0: string) => string | null = (path) => {
  if (!path) {
    return null;
  }
  const currentPathHasProtocol = !!new Uri(path).protocol();
  return currentPathHasProtocol ? path : new Uri(path).setProtocol(urlConstants.protocol).toString();
};

/**
 * Builds an URL for teach-program app.
 * like https://www.coursera.org/teach-program/imba/analytics...
 */
export const buildTeachProgramUrl: UrlBuilderFunc = (slug, path = '') => {
  return urlJoin(urlConstants.absoluteDomainRootWithProcotocol, urlConstants.programRoot, slug, path);
};

/**
 * Redirect in server or client environment
 */

export const redirectClientOrServer: (url: string, statusCode?: number) => void = (url, statusCode = 302) => {
  if (inServerContext) {
    throw new RedirectError(statusCode, url);
  } else {
    redirect.setLocation(url);
  }
};

/**
 * Note: This builds Zendesk-style URLs, however that is actually desired even though we have moved away from Zendesk.  See https://go.dkandu.me/help-links-format
 */
export const buildLearnerHelpUrl = (articleId?: string | null, sectionId?: string) => {
  if (articleId) {
    return `${urlConstants.learnerHelpCenterHome}/hc/articles/${articleId}`;
  } else if (sectionId) {
    return `${urlConstants.learnerHelpCenterHome}/hc/sections/${sectionId}`;
  } else {
    return urlConstants.learnerHelpCenterHome;
  }
};

/**
 * Note: This builds Zendesk-style URLs, however that is actually desired even though we have moved away from Zendesk.  See https://go.dkandu.me/help-links-format
 */
export const buildPartnerHelpUrl = (
  articleId?: string | null,
  categoryId?: string | null,
  sectionId?: string
): string => {
  if (articleId) {
    return `${urlConstants.partnerHelpCenterHome}/hc/articles/${articleId}`;
  } else if (categoryId) {
    return `${urlConstants.partnerHelpCenterHome}/hc/categories/${categoryId}`;
  } else if (sectionId) {
    return `${urlConstants.partnerHelpCenterHome}/hc/sections/${sectionId}`;
  } else {
    return urlConstants.partnerHelpCenterHome;
  }
};

/**
 * Builds an S3 URL for the coursera_assets bucket, like
 * https://coursera_assets.s3.amazonaws.com/browse/domains/biology.jpg */
export const buildCourseraAssetsUrl: UrlBuilderFunc = (filePath: string) => `${config.url.resource_assets}${filePath}`;

type QueryParams = { [key: string]: string };

/**
 * Returns URL params as a keyed javascript object
 * @param {string} href - complete URL
 * @returns {QueryParams} - keyed javascript object <string,string>
 */
export const getUrlParams = (href: string): QueryParams => {
  try {
    const params: QueryParams = {};
    const url = new URL(href);
    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  } catch (e) {
    Sentry.captureException(e);
    return {};
  }
};

/**
 * Determines the appropriate XDP (Extensible Description Page) subfolder prefix based on the current pathname.
 * If XDP subfolders are enabled and the user is not authenticated, the function checks whether the pathname contains a language and locale code.
 * If the language and locale code is supported, it is returned; otherwise, an empty string is returned.
 *
 * @param {string} pathname - The current URL pathname to be evaluated for subfolder locale codes.
 * @param {boolean} isXdpSubfolderEnabled - A flag indicating whether XDP subfolders are enabled.
 * @returns {string} The subfolder language and locale code if valid and subfolders are enabled, or an empty string.
 */
export const getXdpSubfolderPrefixPath = (pathname: string, isXdpSubfolderEnabled: boolean) => {
  const shouldUseXdpSubfolderLocaleCode = !user.isAuthenticatedUser() && isXdpSubfolderEnabled;
  const subfolderLanguageAndLocale = urlPathLocaleSubfolderPatternMatcher(pathname)?.languageAndLocale;
  const prefixPath =
    shouldUseXdpSubfolderLocaleCode &&
    subfolderLanguageAndLocale &&
    supportedSubfolderLocaleCodes.includes(subfolderLanguageAndLocale)
      ? urlPathLocaleSubfolderPatternMatcher(pathname)?.languageAndLocale
      : '';

  return prefixPath;
};

/**
 * Returns origin with absolute path without search string
 * @param {string} href - complete URL
 * @returns {string} - origin with absolute path without search string
 */
export const getUrlSource = (href: string): string => {
  try {
    const url = new URL(href);
    return `${url.origin}${url.pathname}`;
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};

/**
 * Turn an absolute or relative URL into a relative URL
 * Useful for transforming API-provided absolute URLs into relative URLs that can be fetched by the same domain that this server is running on.
 * Useful for dodging CORS when the local dev server supports proxying `/api/` anyways.
 * @param urlString A relative or absolute URL
 * @returns The URL without protocol nor domain
 */
export const maybeStripProtocolAndDomain = (urlString: string) => {
  const url = new URL(urlString);
  return url.pathname + url.search;
};

/**
 * Returns if a link goes to a coursera.org URL (false) or if it goes elsewhere (true).
 * Supported cases:
 * - Absolute URL (https://coursera.org)
 * - Relative URL (/degrees)
 * - Double slash (//google.com)
 * - Link without protocol (google.com)
 * @param href A string, formatted as a URL (like `https://www.coursera.org`) or relative path (like `/degrees` or `/`).
 * @returns boolean | null. Boolean if it is an external link or not, and `null` if the string is not one of the supported above cases.
 */
export const isExternalLink = (href: string) => {
  try {
    const url = new URL(href);
    return !domainRegex.test(url.hostname);
  } catch (e) {
    if (linkWithDoubleSlash.test(href)) {
      try {
        const url = new URL('https:' + href);
        return !domainRegex.test(url.hostname);
      } catch (er) {
        return catchError('isExternalLink failed: ', er);
      }
    } else if (href.startsWith('/')) {
      try {
        // eslint-disable-next-line no-restricted-syntax
        const url = new URL(href, 'https://coursera.org');
        return !domainRegex.test(url.hostname);
      } catch (er) {
        return catchError('isExternalLink failed: ', er);
      }
    } else if (linkWithoutProtocol.test(href)) {
      try {
        const url = new URL('https://' + href);
        return !domainRegex.test(url.hostname);
      } catch (er) {
        return catchError('isExternalLink failed: ', er);
      }
    } else {
      return catchError('isExternalLink failed: ', e);
    }
  }
};
