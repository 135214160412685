import _t from 'i18n!nls/search-common';

export const DEFAULT_SEARCH_FILTERS = [
  'topic',
  'skills',
  'productDifficultyLevel',
  'productDuration',
  'productTypeDescription',
  'partners',
  'language',
  'subtitleLanguage',
];

// according to: https://tools.coursera.org/mega/query/k6scID0bEe6aV1lZYyhxWA@23
export const DEFAULT_SEARCH_FILTERS_SORTED_BY_USAGE = [
  'topic',
  'language',
  'productTypeDescription',
  'productDifficultyLevel',
  'productDuration',
  'skills',
  'subtitleLanguage',
  'partners',
];

export const LANGUAGE_FILTER_KEYS = ['language', 'subtitleLanguage'];
export const IS_PART_OF_COURSERA_PLUS = 'isPartOfCourseraPlus';
export const IS_PART_OF_COURSERA_LITE = 'isPartOfCourseraLite';
export const IS_FOR_CREDIT = 'isCreditEligible';

export const SEARCH_FILTERS_WITH_COURSERA_PLUS = [IS_PART_OF_COURSERA_PLUS, ...DEFAULT_SEARCH_FILTERS];

export const SEARCH_FILTERS_WITH_COURSERA_PLUS_AND_FOR_CREDIT = [
  IS_FOR_CREDIT,
  IS_PART_OF_COURSERA_PLUS,
  ...DEFAULT_SEARCH_FILTERS,
];
export const SEARCH_FILTERS_WITH_FOR_CREDIT = [IS_FOR_CREDIT, ...DEFAULT_SEARCH_FILTERS];

export const SEARCH_FILTERS_FOR_URL_SYNC = SEARCH_FILTERS_WITH_COURSERA_PLUS_AND_FOR_CREDIT;

// The isPartOfCourseraLite filter is not yet supported by Algolia
// but we are using it somewhat hackily on the search page
// (isPartOfCourseraLite uses isPartOfCourseraPlus under the hood)
export const SEARCH_FILTER_PARAMS_NOT_SUPPORTED_BY_ALGOLIA = [IS_PART_OF_COURSERA_LITE];

export const FILTER_OVERFLOW_WIDTH = 1023;
export const NUMBER_OF_FILTER_ITEMS_TO_SHOW = 4;
export const NUMBER_OF_ITEMS_TO_FIT_IN_FILTER_DROPDOWN = 9;
export const DESKTOP_FILTER_LIMIT = 1000;

/**
 * @deprecated: use search-provider and generic search=application filters constant above
 */
export const ALGOLIA_SEARCH_FILTERS = [
  'topic',
  'skills',
  'productDifficultyLevel',
  'productDurationEnum',
  'entityTypeDescription',
  'partners',
  'allLanguages',
  'hasLabAssignments',
];

export type FilterData = {
  getName: () => string;
  getNameMobile: () => string;
  property: string;
  showOutsideOfAllTab: boolean;
  showAllItems: boolean;
};

export const FILTER_INDEX_TO_FILTER_DATA: Record<string, FilterData | undefined> = {
  allLanguages: {
    getName: () => _t('Language'),
    getNameMobile: () => _t('Language'),
    property: 'allLanguages',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  language: {
    getName: () => _t('Language'),
    getNameMobile: () => _t('Language'),
    property: 'language',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  subtitleLanguage: {
    getName: () => _t('Subtitles'),
    getNameMobile: () => _t('Subtitles'),
    property: 'subtitleLanguage',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  productDifficultyLevel: {
    getName: () => _t('Level'),
    getNameMobile: () => _t('Level'),
    property: 'productDifficultyLevel',
    showOutsideOfAllTab: false,
    showAllItems: true,
  },
  productDurationEnum: {
    getName: () => _t('Duration'),
    getNameMobile: () => _t('Duration'),
    property: 'productDurationEnum',
    showOutsideOfAllTab: false,
    showAllItems: true,
  },
  productDuration: {
    getName: () => _t('Duration'),
    getNameMobile: () => _t('Duration'),
    property: 'productDuration',
    showOutsideOfAllTab: false,
    showAllItems: true,
  },
  partners: {
    getName: () => _t('Partner'),
    getNameMobile: () => _t('Partner'),
    property: 'partners',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  skills: {
    getName: () => _t('Skills'),
    getNameMobile: () => _t('Skills'),
    property: 'skills',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  entityTypeDescription: {
    getName: () => _t('Learning Product'),
    getNameMobile: () => _t('Learning Product'),
    property: 'entityTypeDescription',
    showOutsideOfAllTab: true,
    showAllItems: true,
  },
  productTypeDescription: {
    getName: () => _t('Learning Product'),
    getNameMobile: () => _t('Learning Product'),
    property: 'productTypeDescription',
    showOutsideOfAllTab: true,
    showAllItems: true,
  },
  isPartOfCourseraPlus: {
    getName: () => _t('Coursera Plus'),
    getNameMobile: () => _t('Coursera Plus'),
    property: IS_PART_OF_COURSERA_PLUS,
    showOutsideOfAllTab: true,
    showAllItems: true,
  },
  topic: {
    getName: () => _t('Subject'),
    getNameMobile: () => _t('Subject'),
    property: 'topic',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  hasLabAssignments: {
    getName: () => _t('Labs'),
    getNameMobile: () => _t('Labs'),
    property: 'hasLabAssignments',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
};

const SEARCH_FILTER_LEARNING_PRODUCT_ORDER = [
  'Short Form Content Video',
  'Short Form Content Lesson',
  'Guided Projects',
  'Courses',
  'Projects',
  'Rhyme Projects',
  'Specializations',
  'Certificates',
  'Professional Certificates',
  'MasterTrack®',
  'MasterTrack® Certificates',
  'Degrees',
  'Postgraduate Diploma',
  'Graduate Certificates',
  'University Certificates',
];

const REIMAGINE_VARIANT_C_LEVEL_ORDER = ['Beginner', 'Intermediate', 'Advanced', 'Mixed'];

const REIMAGINE_VARIANT_C_DURATION_ORDER = [
  'Under 60 Minutes',
  'Less Than 2 Hours',
  '1-4 Weeks',
  '1-3 Months',
  '3-6 Months',
  '3+ Months',
];

export const SEARCH_FILTER_OPTION_ORDER_OVERRIDES_REIMAGINE_VARIANT_C: Record<string, string[] | undefined> = {
  productDifficultyLevel: REIMAGINE_VARIANT_C_LEVEL_ORDER,
  productDurationEnum: REIMAGINE_VARIANT_C_DURATION_ORDER,
  entityTypeDescription: SEARCH_FILTER_LEARNING_PRODUCT_ORDER,
};

export const shortFormContentBetaFilterLabels: Set<string> = new Set<string>(['Videos', 'Lessons', 'Under 60 Minutes']);
