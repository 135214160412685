import epicClient from 'bundles/epic/clients/siteMisc';

import type { GraphQLGatewayLinks } from './constants';

/**
 * allows to set a specific link to use for queries by name.
 */
export const graphqlGatewayQueryLinkOverrideFactory =
  (client: typeof epicClient) =>
  (operationName: string, appName?: string): GraphQLGatewayLinks | string | null => {
    const overrides = {
      ...client.get('graphqlGatewayQueryLinkOverride'),
      ...client.get('graphqlGatewayQueryLinkOverrideSecondary'),
    };
    if (overrides[operationName]) {
      if (typeof overrides[operationName] === 'string') {
        return overrides[operationName];
      }

      if (appName && typeof overrides[operationName][appName] === 'string') {
        return overrides[operationName][appName];
      }
    }

    return null;
  };

export const graphqlGatewayQueryLinkOverride = graphqlGatewayQueryLinkOverrideFactory(epicClient);
