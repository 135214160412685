import { memoize } from 'lodash';

import type { Options } from './base';
import type Transport from './base';
import { DEFAULT_BATCH_URL, DEFAULT_URL } from './base';
import BeaconTransport from './beacon';
import FetchTransport from './fetch';
import IframeTransport from './iframe';
import LoggerTransport from './logger';
import PriorityTransport from './priority';
import ScriptTransport from './script';

const createDefaultTransport = memoize(
  (options: Partial<Options>): Transport =>
    new PriorityTransport({
      ...options,
      transports: [FetchTransport, BeaconTransport, IframeTransport, ScriptTransport],
    })
);

export type { Transport };
export {
  BeaconTransport,
  createDefaultTransport,
  DEFAULT_BATCH_URL,
  DEFAULT_URL,
  FetchTransport,
  IframeTransport,
  LoggerTransport,
  PriorityTransport,
  ScriptTransport,
};
