import BaseTransport from './base';

/**
 * Transport that uses an iframe to send events. It does not support batching.
 *
 * An iframe is created and added to the body of the document. A form is also
 * created and added to the body of the document. The form will have the event
 * data as hidden inputs. The form will be submitted to the iframe.
 */
class IframeTransport extends BaseTransport {
  /**
   * Create a form with the given data.
   *
   * @param data
   */
  createForm(data: Record<string, unknown>) {
    const form = document.createElement('form');

    // Set the form attributes
    form.action = this.url;
    form.method = 'POST';
    form.target = data.guid as string;

    // Set the form invisible
    form.style.display = 'none';

    // Set the data inside the form
    Object.entries(data).forEach(([key, value]) => {
      const input = document.createElement('input');

      // Set the input attributes
      input.type = 'hidden';
      input.name = key;
      input.value = typeof value === 'object' ? JSON.stringify(value) : String(value);

      // Set the input inside the form
      form.appendChild(input);
    });

    return form;
  }

  /**
   * Create an iframe with the given guid.
   *
   * @param guid
   */
  createIframe(guid: string) {
    const iframe = document.createElement('iframe');

    iframe.style.display = 'none';
    iframe.name = guid;

    if (iframe.contentWindow) {
      iframe.contentWindow.name = guid;
    }

    return iframe;
  }

  /**
   * Create a form and an iframe and submit the form to the iframe.
   *
   * @param data
   */
  request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    return new Promise((ok) => {
      const iframe = this.createIframe(data.guid as string);
      const form = this.createForm(data);

      document.body.appendChild(iframe);
      document.body.appendChild(form);

      iframe.onerror = () => {
        ok(new Response(undefined, { status: 500 }));
      };

      iframe.onload = () => {
        ok(new Response(undefined, { status: 200 }));
      };

      form.submit();
    });
  }
}

export default IframeTransport;
