const Colors = {
  white: 'var(--cds-color-white-0)',

  black: {
    500: 'var(--cds-color-grey-975)',
    700: 'var(--cds-color-grey-975)',
  },

  gray: {
    50: 'var(--cds-color-grey-25)',
    100: 'var(--cds-color-grey-25)',
    200: 'var(--cds-color-grey-50)',
    300: 'var(--cds-color-grey-50)',
    400: 'var(--cds-color-grey-200)',
    500: 'var(--cds-color-grey-300)',
    600: 'var(--cds-color-grey-500)',
    700: 'var(--cds-color-grey-600)',
    800: 'var(--cds-color-grey-700)',
    900: 'var(--cds-color-grey-900)',
  },

  blue: {
    50: 'var(--cds-color-blue-25)',
    100: 'var(--cds-color-blue-50)',
    200: 'var(--cds-color-blue-100)',
    300: 'var(--cds-color-blue-200)',
    400: 'var(--cds-color-blue-300)',
    500: 'var(--cds-color-blue-500)',
    600: 'var(--cds-color-blue-700)',
    700: 'var(--cds-color-blue-800)',
    800: 'var(--cds-color-blue-900)',
    900: 'var(--cds-color-blue-950)',
  },

  purple: {
    50: 'var(--cds-color-purple-25)',
    100: 'var(--cds-color-purple-50)',
    200: 'var(--cds-color-purple-100)',
    300: 'var(--cds-color-purple-300)',
    400: 'var(--cds-color-purple-400)',
    500: 'var(--cds-color-purple-500)',
    600: 'var(--cds-color-purple-700)',
    700: 'var(--cds-color-purple-900)',
    800: 'var(--cds-color-purple-950)',
    900: 'var(--cds-color-purple-975)',
  },

  yellow: {
    50: 'var(--cds-color-yellow-25)',
    100: 'var(--cds-color-yellow-50)',
    200: 'var(--cds-color-yellow-50)',
    300: 'var(--cds-color-yellow-100)',
    400: 'var(--cds-color-yellow-200)',
    500: 'var(--cds-color-yellow-200)',
    600: 'var(--cds-color-yellow-300)',
    700: 'var(--cds-color-yellow-400)',
    800: 'var(--cds-color-yellow-700)',
    900: 'var(--cds-color-yellow-800)',
  },

  aqua: {
    50: 'var(--cds-color-pink-25)',
    100: 'var(--cds-color-pink-25)',
    200: 'var(--cds-color-pink-50)',
    300: 'var(--cds-color-pink-100)',
    400: 'var(--cds-color-pink-200)',
    500: 'var(--cds-color-pink-200)',
    600: 'var(--cds-color-pink-400)',
    700: 'var(--cds-color-pink-600)',
    800: 'var(--cds-color-pink-700)',
    900: 'var(--cds-color-pink-950)',
  },

  green: {
    50: 'var(--cds-color-green-25)',
    100: 'var(--cds-color-green-50)',
    200: 'var(--cds-color-green-100)',
    300: 'var(--cds-color-green-200)',
    400: 'var(--cds-color-green-300)',
    500: 'var(--cds-color-green-500)',
    600: 'var(--cds-color-green-600)',
    700: 'var(--cds-color-green-700)',
    800: 'var(--cds-color-green-800)',
    900: 'var(--cds-color-green-900)',
  },

  red: {
    50: 'var(--cds-color-red-25)',
    100: 'var(--cds-color-red-50)',
    200: 'var(--cds-color-red-100)',
    300: 'var(--cds-color-red-200)',
    400: 'var(--cds-color-red-300)',
    500: 'var(--cds-color-red-500)',
    600: 'var(--cds-color-red-600)',
    700: 'var(--cds-color-red-700)',
    800: 'var(--cds-color-red-900)',
    900: 'var(--cds-color-red-950)',
  },

  orange: {
    50: 'var(--cds-color-yellow-25)',
    100: 'var(--cds-color-yellow-50)',
    200: 'var(--cds-color-yellow-100)',
    300: 'var(--cds-color-yellow-200)',
    400: 'var(--cds-color-yellow-300)',
    500: 'var(--cds-color-yellow-400)',
    600: 'var(--cds-color-yellow-600)',
    700: 'var(--cds-color-yellow-700)',
    800: 'var(--cds-color-yellow-800)',
    900: 'var(--cds-color-yellow-900)',
  },

  pistachio: {
    50: 'var(--cds-color-green-25)',
    100: 'var(--cds-color-green-25)',
    200: 'var(--cds-color-green-50)',
    300: 'var(--cds-color-green-100)',
    400: 'var(--cds-color-green-200)',
    500: 'var(--cds-color-green-300)',
    600: 'var(--cds-color-green-500)',
    700: 'var(--cds-color-green-600)',
    800: 'var(--cds-color-green-800)',
    900: 'var(--cds-color-green-900)',
  },

  berry: {
    50: 'var(--cds-color-pink-25)',
    100: 'var(--cds-color-pink-25)',
    200: 'var(--cds-color-pink-50)',
    300: 'var(--cds-color-pink-50)',
    400: 'var(--cds-color-pink-200)',
    500: 'var(--cds-color-pink-500)',
    600: 'var(--cds-color-pink-600)',
    700: 'var(--cds-color-pink-700)',
    800: 'var(--cds-color-pink-800)',
    900: 'var(--cds-color-pink-950)',
  },
};

export type Palette = Readonly<typeof Colors>;
export type PaletteOptions = typeof Colors;

export default Colors;
