/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import Retracked from 'js/app/retracked';
import { isUserRightToLeft } from 'js/lib/language';

import {
  getCareerLearningPathsItem,
  getCertificateItem,
  getDegreeItem,
  getFindYourNewCareer,
  getFreeItem,
  getRootItem,
} from 'bundles/browse/components/PageHeader/constants';
import styles from 'bundles/megamenu/components/__styles__/MegaMenuSubPanel';
import CLPTab from 'bundles/megamenu/components/tabs/CLPTab';
import CertificatesTab from 'bundles/megamenu/components/tabs/CertificatesTab';
import DegreesTab from 'bundles/megamenu/components/tabs/DegreesTab';
import DomainTab from 'bundles/megamenu/components/tabs/DomainTab';
import type { MegaMenuData, MenuItem } from 'bundles/megamenu/types/MenuData';

type Props = {
  selectedDomain?: MenuItem;
  closeMenu: () => void;
  closeSubMenu: () => void;
  setSubMenuWrapper: (node: HTMLElement) => void;
  megaMenuData: MegaMenuData[];
  isUsingKeyboard?: boolean;
};

type trackingLogicParam = {
  shouldShowDomainMenu: boolean;
  shouldShowDegreeTab: boolean;
  shouldShowCareerLearningPathsMenu: boolean;
  shouldShowCertificateTab: boolean;
};

class MegaMenuSubPanel extends React.Component<Props> {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  trackingLogic = ({
    shouldShowDomainMenu,
    shouldShowDegreeTab,
    shouldShowCareerLearningPathsMenu,
    shouldShowCertificateTab,
  }: trackingLogicParam) => {
    // this tracking logic is needed due to CSS hiding technique refrain us from using retracked visibility tracking
    const { _eventData } = this.context;
    const { selectedDomain } = this.props;
    const trackingData = { menuName: selectedDomain?.menuName, id: selectedDomain?.id };

    let trackingName = '';
    if (shouldShowDomainMenu) {
      trackingName = 'mega_menu_domain_tab';
    } else if (shouldShowDegreeTab) {
      trackingName = 'mega_menu_degree_tab';
    } else if (shouldShowCareerLearningPathsMenu) {
      trackingName = 'mega_menu_clp_tab';
    } else if (shouldShowCertificateTab) {
      trackingName = 'mega_menu_certificate_tab';
    }

    if (trackingName) {
      Retracked.trackComponent(_eventData, trackingData, trackingName, 'view');
    }
  };

  render() {
    const { selectedDomain, closeMenu, closeSubMenu, setSubMenuWrapper, megaMenuData, isUsingKeyboard } = this.props;
    const freeItemId = getFreeItem().id;
    const findYourNewCareerId = getFindYourNewCareer().id;
    const degreeDomainId = getDegreeItem().id;
    const certificateDomainId = getCertificateItem().id;
    const careerLearningPathsDomainId = getCareerLearningPathsItem().id;
    const rootDomainId = getRootItem().id;

    const isSelected = !!selectedDomain;
    const isRootSelected = selectedDomain?.id === rootDomainId;
    const isDegree = selectedDomain?.id === degreeDomainId;

    const shouldShowDomainMenu: boolean =
      isSelected &&
      !isRootSelected &&
      selectedDomain?.id !== degreeDomainId &&
      selectedDomain?.id !== certificateDomainId &&
      selectedDomain?.id !== careerLearningPathsDomainId &&
      selectedDomain?.id !== freeItemId &&
      selectedDomain?.id !== findYourNewCareerId;

    const shouldShowDegreeTab = isSelected && isDegree;
    const shouldShowCareerLearningPathsMenu = selectedDomain?.id === careerLearningPathsDomainId;
    const shouldShowCertificateTab = selectedDomain?.id === certificateDomainId;

    this.trackingLogic({
      shouldShowDomainMenu,
      shouldShowDegreeTab,
      shouldShowCareerLearningPathsMenu,
      shouldShowCertificateTab,
    });

    return (
      <div
        className="rc-MegaMenuSubPanel"
        css={styles.megaMenuSubPanel}
        ref={(node) => {
          if (node) setSubMenuWrapper(node);
        }}
        role="menuitem"
        tabIndex={0}
        onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
          switch (evt.key) {
            case isUserRightToLeft() ? 'ArrowRight' : 'ArrowLeft':
            case 'Escape':
              evt.stopPropagation();
              closeSubMenu();
              break;
            default:
          }
        }}
      >
        {megaMenuData?.map((section) =>
          section.sectionMenus.map((menuItem) => {
            switch (menuItem.id) {
              case rootDomainId:
                return null;
              case degreeDomainId:
                return (
                  <FocusTrap key={menuItem.id} active={shouldShowDegreeTab && isUsingKeyboard}>
                    <div
                      className={shouldShowDegreeTab ? 'active' : ''}
                      aria-hidden={!shouldShowDegreeTab}
                      css={[styles.sectionWrapper, !shouldShowDegreeTab && styles.isHidden]}
                    >
                      <DegreesTab data={menuItem?.subMenuData} closeMenu={closeMenu} />
                    </div>
                  </FocusTrap>
                );
              case certificateDomainId:
                return (
                  <FocusTrap key={menuItem.id} active={shouldShowCertificateTab && isUsingKeyboard}>
                    <div
                      className={shouldShowCertificateTab ? 'active' : ''}
                      aria-hidden={!shouldShowCertificateTab}
                      css={[styles.sectionWrapper, !shouldShowCertificateTab && styles.isHidden]}
                    >
                      <CertificatesTab data={menuItem?.subMenuData} closeMenu={closeMenu} />
                    </div>
                  </FocusTrap>
                );
              case careerLearningPathsDomainId:
                return (
                  <FocusTrap key={menuItem.id} active={shouldShowCareerLearningPathsMenu && isUsingKeyboard}>
                    <div
                      className={shouldShowCareerLearningPathsMenu ? 'active' : ''}
                      aria-hidden={!shouldShowCareerLearningPathsMenu}
                      css={[styles.sectionWrapper, !shouldShowCareerLearningPathsMenu && styles.isHidden]}
                    >
                      <CLPTab data={menuItem?.subMenuData} closeMenu={closeMenu} />
                    </div>
                  </FocusTrap>
                );
              default: {
                const shouldShowCurrentDomainTab = shouldShowDomainMenu && selectedDomain?.id === menuItem.id;
                return (
                  <FocusTrap key={menuItem.id} active={shouldShowCurrentDomainTab && isUsingKeyboard}>
                    <div
                      className={shouldShowCurrentDomainTab ? 'active' : ''}
                      aria-hidden={!shouldShowCurrentDomainTab}
                      css={[styles.sectionWrapper, !shouldShowCurrentDomainTab && styles.isHidden]}
                    >
                      <DomainTab menuItem={menuItem} closeMenu={closeMenu} />
                    </div>
                  </FocusTrap>
                );
              }
            }
          })
        )}
      </div>
    );
  }
}

export default MegaMenuSubPanel;
